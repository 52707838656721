import React ,{Component} from 'react';

import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles'; 
import Button from '@mui/material/Button';
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});






export default class  AddressForm extends Component {
constructor(props) {
    super();

    this.state = { Addresstitle: '' ,adtype:0,stendisabled:false,btnsubmit:"Submit",Country:'',Address: "",City:"",
	state:"",PIN:"",pagename:'',redirect:false,erroropen:false,shipcheck:false,alerttype:"error",errormessage:"",checked:true};
   
}
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
  
}
 Getvieworderid(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
 componentDidMount() {
		let search = window.location.href;
let strtype = this.Getvieworderid('type',search); 
		if(localStorage.getItem("ISIuserid")!==null)
	{
		
	}else{
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}
	if(strtype!==null)
	{
	this.setState({
            adtype: strtype,
			
          })
	}
	
 }  handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }
 handleSubmit= (event) => {
	 
	   var self = this;
	   
    event.preventDefault();
	 let Addresstitle = this.state.Addresstitle;
    let Country = this.state.Country;
	 let Address = this.state.Address;
	  let city = this.state.City;
	let state = this.state.state; 
	let PIN = this.state.PIN; 
let ischeck = this.state.checked;	
let shipcheck = this.state.shipcheck;

    if(Addresstitle=="")
  {	  
    this.setState({ errormessage:'Please Enter Address Name',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
   if(Address=="")
  {	  
    this.setState({ errormessage:'Please Enter Address',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
  if(city=="")
  {	  
   this.setState({ errormessage:'Please Enter City',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
 
  }
   if(state=="")
  {	  
    this.setState({ errormessage:'Please Enter State',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
   if(Country=="")
  {	  
  this.setState({ errormessage:'Please Enter Country',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })

  }
   if(PIN=="")
  {	  
  this.setState({ errormessage:'Please Enter Postal Code',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })

  }
  
  this.setState({ stendisabled:true,btnsubmit:"Processing..."})
  let istype="0"; let strshipcheck="1";
if(ischeck==true)
					  {
						  istype="1";
					  }
			
if(shipcheck==true)
					  {
						  strshipcheck="3";
					  }		  
					  
					  
	var formData = new FormData();
	 formData.append('action','insertorupdate') 
	  formData.append('ad_cs_id',localStorage.getItem("ISCustid")) 
	   formData.append('userid',localStorage.getItem("ISIuserid"))
formData.append('ad_isdefault',istype)
formData.append('ad_billingname',Addresstitle)
formData.append('ad_billingaddress',Address)
if(this.state.adtype!==0)
{
formData.append('ad_type',this.state.adtype)
}
else{
	formData.append('ad_type',strshipcheck)
}
formData.append('ad_billingcity',city)
formData.append('ad_billingstate',state)	
formData.append('ad_billingpostalcode',PIN)
formData.append('ad_billingcountry',Country)	
axios.post(Serverurl+"ajaxaddress.php", formData, {
        }).then(res => {
			
if(this.state.adtype===0)
{
			this.setState({ stendisabled:false,btnsubmit:"Submit",redirect:true,pagename:'/Myaddresslis?type=1'})
					
}
if(this.state.adtype!==0)
{
			this.setState({ stendisabled:false,btnsubmit:"Submit",redirect:true,pagename:'/Myaddresslis?type='+this.state.adtype})
					
}			

		});		
 }
Setshipping = (event) => 
{
	this.setState({checked: !this.state.checked});
};
Setshippingaddress = (event) => 
{
	this.setState({shipcheck: !this.state.shipcheck});
};

render() {
 
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
	}
return (

<div className="adminform" >
<Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse>
<form className={useStyles.form} onSubmit={this.handleSubmit}>

<ul>
<li>
<p>Address Name</p>
<p>
<TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            id="Addresstitle"   onChange={this.myChangeHandler}
            placeholder="Address Name"
            name="Addresstitle" 
           inputProps={{maxLength:150, tabIndex: "1"  }} 
           
          /></p></li><li><p>Address </p><p>
<TextField
            variant="outlined"
            margin="normal"
            
            fullWidth   onChange={this.myChangeHandler}
            id="Address"
            placeholder="Address"
            name="Address" inputProps={{tabIndex: "2"  }} 
           multiline rows="2"
          /></p>



</li><li><p>City </p><p>

<TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            id="City"   onChange={this.myChangeHandler}
            placeholder="City"
            name="City"   inputProps={{maxLength:150, tabIndex: "3"  }} 
            autoComplete="City"
           
          /></p></li><li><p>Province </p><p>
<TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            id="state"   onChange={this.myChangeHandler}
            placeholder="Province"  inputProps={{maxLength:150, tabIndex: "4"  }} 
            name="state" 
          
           
          /></p></li><li><p>Country </p><p>

		  
		  <FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.Country}
		onChange={this.myChangeHandler}
		name='Country'
		inputProps={{tabIndex: "5"}}
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value="">Select</option>
		<option value="UK">UK</option>
		<option value="US">US</option>		
		<option value="Canada">Canada</option>
<option value="Europe">Europe</option>	<option value="Ireland">Ireland</option>			
		</Select>
		</FormControl>
		  
		  </p></li><li><p>Postal Code </p><p>
<TextField
            variant="outlined"
            margin="normal"
            
            fullWidth
            id="PIN"   onChange={this.myChangeHandler}
            placeholder="Postal Code"
            name="PIN"  inputProps={{maxLength:8, tabIndex: "6"  }}
            autoComplete="PIN"
            
          /></p></li><li><p>&nbsp;</p>
<p> <FormControlLabel
        label="Default Address"
        control={ <Checkbox  checked={this.state.checked} color="success" onChange={this.Setshipping}  inputProps={{ 'aria-label': 'controlled' }} />}
      /></p></li>
	  
	  {this.state.adtype!=="2" && (
	  <li><p>&nbsp;</p>
<p> <FormControlLabel
        label="Make as Shipping Address also"
        control={ <Checkbox  checked={this.state.shipcheck} color="success" onChange={this.Setshippingaddress}  inputProps={{ 'aria-label': 'controlled' }} />}
      /></p></li>)}
	  <li className="stadrp">
 <Button
            type="submit"
             disabled={this.state.stendisabled}
            variant="contained" 
            className="primarybtn" 
            id="submitaddress" inputProps={{tabIndex: "7"  }}
         
          >
           {this.state.btnsubmit} 
          </Button>
</li></ul>
</form>
	<div className="clear"></div>
</div>



     );
    }
   }
