import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import OutlinedInput from '@mui/material/OutlinedInput';
import logo from './images/login-screen.png';
import Generatesession from './GenerateSession';

export default class Login extends Component {
	
	 constructor(props) {
    super(props);
	 this.state = {Username:'',Password:'',btndisabled:false,pagename:'',btntext:'Log IN',errormessage:'',open:false,checked:true,showPassword:false}
	 }
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val}); 
} 
handleChange = (event) => 
{
	this.setState({checked: !this.state.checked});
};
handleClickShowPassword = (event) => 
{
	this.setState({showPassword: !this.state.showPassword});
};
componentDidMount() {




	if(localStorage.getItem("ISIuserid")!==null)
	{
			if(	localStorage.getItem("ISIrole")==="1")
			{
		this.setState({redirect: true,open:false,
							pagename:'/Admindashboard'
						  })
				
			}else if(	localStorage.getItem("ISIrole")==="2")
			{
		this.setState({redirect: true,open:false,
							pagename:'/Salesdashboard'
						  })
				
			}
			else if(	localStorage.getItem("ISIrole")==="3")
			{
		this.setState({redirect: true,open:false,
							pagename:'/Userdashboard'
						  })
				
			}
	}
	else{
				 localStorage.getItem("ISIUsername");
						  localStorage.getItem("ISIpwd");
						  
					this.setState({Username:localStorage.getItem("ISIUsername"),Password: localStorage.getItem("ISIpwd")});	  
			}	
	

}
handleClickLogin= (event) => 
		{
		event.preventDefault();
				
				let Username = this.state.Username;
				let Password = this.state.Password;
				let ischeck = this.state.checked;	
				if(Username==''){
						
				this.setState({errormessage:"Please Enter Emailid",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
			
				if(Password==''){
						this.setState({errormessage:"Please Enter Password",open:true});
						 window.scrollTo({        top: 0     })
						 return false; 
					
				}
				
				this.setState({btndisabled:true,btntext:'Processing...'
						  })
var formData = new FormData();
				formData.append('action','Login') 	
 
				formData.append('cs_username',Username)
				formData.append('cs_password',Password)
				
	
	
				axios.post(Serverurl+"ajaxcustomer.php", formData, {
				}).then(res => {
				
				
				if(res.data.length>0){
					
					if(ischeck==true)
					  {
						  
						   localStorage.setItem("ISIUsername",Username);
						  localStorage.setItem("ISIpwd",Password);
						
					  }
					  else{
							 localStorage.removeItem("ISIUsername");
							  localStorage.removeItem("ISIpwd"); 
						  }
						  this.setState({btndisabled:false,btntext:'Log IN'
						  })
				localStorage.setItem("ISIUsername",Username);
				localStorage.setItem("ISIuserid",res.data[0].cs_id);
				localStorage.setItem("ISIrole",res.data[0].cs_role);
				localStorage.setItem("ISorcount",res.data[0].orcount);
				 localStorage.setItem("IScartqty",0)
				  localStorage.setItem("token", "this_is_a_demo_token")
				localStorage.setItem("ISINames",res.data[0].custname);
			if(localStorage.getItem("ISIsession")===null)
			{
			   let strguid = Generatesession();			
			 localStorage.setItem("ISIsession",strguid);
		 				
			}	
			if(	localStorage.getItem("ISIrole")==="1")
			{
		this.setState({redirect: true,open:false,
							pagename:'/Admindashboard'
						  })
				
			}else if(	localStorage.getItem("ISIrole")==="2")
			{
		this.setState({redirect: true,open:false,
							pagename:'/Salesdashboard'
						  })
				
			}
			else if(	localStorage.getItem("ISIrole")==="3")
			{
				localStorage.setItem("ISCustid",res.data[0].cs_id);
				
		this.setState({redirect: true,open:false,
							pagename:'/Userdashboard'
						  })
				
			}
				}
				else
				{
					this.setState({errormessage:"Not a valid login details",open:true,btndisabled:false,btntext:'Log IN'});
		 window.scrollTo({        top: 0     })
				}
				
				});
				
				
			
		}
				
	 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
return (
<React.Fragment>
<div className="loginmain">
<div className="mainscreen-row">
<div className="main">
	<div className="leftbandlog-col">
		<img src={logo}/>
		
		
	</div>
	<div className="contentseclog-col">
		<div className="logindiv">
		<h1>Sign In</h1>
			 <p>  <Collapse in={this.state.open}>
							<Alert
							  action={
								<IconButton
								  aria-label="close"
								  color="inherit"
								  size="small"
								  onClick={() => {
								   this.handleErrorclose();
								  }}
								>
								  <CloseIcon fontSize="inherit" />
								</IconButton>
							  } severity="error"
							>
							{this.state.errormessage}
							</Alert>
					</Collapse></p>
					
			<p><TextField id="txtUsername" fullWidth  variant="standard" name="Username" value={this.state.Username}  inputProps={{tabIndex: "1",maxLength:150}} onChange={this.myChangeHandler} placeholder="Enter Username"/></p>
			<p>
			 <FormControl fullWidth >
          
          <Input id="txtPassword" name="Password" fullWidth value={this.state.Password}  inputProps={{tabIndex: "2",maxLength:15}} onChange={this.myChangeHandler} placeholder="Password"
            type={this.state.showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
</p>
			<p> <FormControlLabel
        label="Remember me"
        control={ <Checkbox  checked={this.state.checked}  onChange={this.handleChange}  inputProps={{ 'aria-label': 'controlled' }} />}
      /></p>
			
			<Button variant="contained" disabled={this.state.btndisabled} className="frmPrimarybtn" onClick={this.handleClickLogin}>
				{this.state.btntext}
</Button>
			
		</div>
	</div>
	
	<div className="clear"></div>
</div>
</div>
</div>
</React.Fragment>
)

	}		
}







