import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';


import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
 
import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles(theme => ({
 
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  
  form: {
    width: '500%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
	 minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  
}));





export default class  AddProduct extends Component {

   constructor(props) {
      super(props)
	  
          this.state = {redirect: false,issellabledisabled:false,year:'',title:'',
     categorydata:[],page: 0 ,prevpage:0,totaldata:0,pi_id:'',pi_image:'',
	 errormessage:"",erropen:false,name:'',imagearr:[],imgCollection:[],ca_mainid:0,
	  order:'desc',prid:'',pr_id:0,pr_code:'',pr_title:'',pr_ca_id:'',pr_tax:'',pr_price:'',pr_wholesaleprice:'',pr_salesprice:'',pr_description:'',ca_mainid:'',
	       categoryarray:[],btnsavedisbaled:false,btnsavetxt:'Save',pr_issellable:true,pr_stockqty:'',
      }
	  
	  
	     
      
  }
  
  
  componentDidMount() {
  
	 this.categorybind();
	 let search = window.location.href;
	 let Prodid = this.getParameterByName('Prid',search);
	if(Prodid!==null)
	{
this.ProductGetData(Prodid);
	}
		
}
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


 ProductGetData(Prodid) {
	

	  
	   axios.get(Serverurl+'ajaxproduct.php?action=list&pr_id='+Prodid)
  .then(resp => {  
		
    if(resp.data.length>0){
		if(resp.data[0].pr_issellable==="1")
		{
			this.setState({ pr_issellable:true });
		}
		
		
    this.setState({ 
     
  
    pr_id:resp.data[0].pr_id,
	pr_title:resp.data[0].pr_title,
	pr_code:resp.data[0].pr_code,
	ca_mainid:resp.data[0].pr_ca_id,
	pr_tax:resp.data[0].pr_tax,
	pr_price:resp.data[0].pr_price,
	pr_wholesaleprice:resp.data[0].pr_wholesaleprice,
	pr_salesprice:resp.data[0].pr_salesprice,
	pr_description:resp.data[0].pr_description,
	pr_stockqty:resp.data[0].pr_stockqty,
	
	
	
  
	

    
    });
	this.onloadProductImagebyid(Prodid);
  
	}else{
	 this.setState({  
	  pr_id:0,
	  pr_title:'',
		pr_code:'',
		pr_ca_id:'',
		pr_price:'',
		pr_wholesaleprice:'',
		pr_description:'',
	 pr_stockqty:'',
	 
	  }); 
 
  
    }
});
	  
 }
  onloadProductImagebyid(pdid){

		 axios.get(Serverurl+'ajaxproductimage.php?action=list&pi_pr_id='+pdid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   imagearr:resp.data,

	});
	
   }
   else{
	  this.setState({ imagearr:[]}); 
   }
  });
  
  	

  
    }
  
 /* 
  	
onloadproductbyid(prid){
	
		 axios.get(Serverurl+'ajaxcategory.php?action=list&pr_id='+prid)
  .then(resp => {  
   if(resp.data.length>0){
	   const items = {
				 
			 title:resp.data[0].ca_title,
			 year:resp.data[0].pr_ca_id,
			  
			};
    this.setState({ 
   	

	autocatname:resp.data[0].ct_title,
	
	
	 strCatdeflt:items, 

	

	

	},()=>{ });
	





  

	// this.onloadeditcatBind(pdid);
   }else{
		
		
		
	 this.setState({  
	 
	
	  }); 
 }
  });
  
  	
	
	
	
  
    }

*/

handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open,
     errormessage:'',erropen:false,
	  });
		this.ProductGetData();
};



myChangeHandler = (event) => {
	
	
  let nam = event.target.name;
  let val = event.target.value;  
 
   const re = /^[0-9.\b]+$/;
 
if(nam==="pr_price"  || nam==="pr_wholesaleprice" || nam==="pr_salesprice"  )
  {if (event.target.value === '' || re.test(event.target.value)) {
   this.setState({[nam]: val}); 
}
  }
 
  else{ this.setState({[nam]: val});
  }
}



 handleChange = (event) => {
      this.setState({open:!this.state.open,
     errormessage:'',erropen:false,
	  });
  };
 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }


 
  SubmitForm= (e) => {


	let pr_id = this.state.pr_id;
	let pr_title = this.state.pr_title;
	let pr_code=this.state.pr_code;
	let ca_mainid=this.state.ca_mainid;
	let pr_tax=this.state.pr_tax;
	let pr_price=this.state.pr_price;
	let pr_wholesaleprice=this.state.pr_wholesaleprice;
	let pr_salesprice=this.state.pr_salesprice;
	let pr_description=this.state.pr_description;
	let pr_stockqty=this.state.pr_stockqty;
	
	let pr_issellable=this.state.pr_issellable;
	
	
	



this.setState({errormessage:"",erropen:false});
	if(pr_title===''){
						
		this.setState({errormessage:"Please Enter Title.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
		
			if(ca_mainid===''){
						
		this.setState({errormessage:"Please Select Category",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
						
			if(pr_code===''){
						
		this.setState({errormessage:"Please Enter Product Code",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
	
				
				
						
	
				
				
			if(pr_price===''){
						
		this.setState({errormessage:"Please Enter  Price",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
			if(pr_wholesaleprice===''){
						
		this.setState({errormessage:"Please Enter  Wholesale Price",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
				
				
				
						
			if(pr_salesprice===''){
						
		this.setState({errormessage:"Please Enter  Sales Price",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
				
							
						
			if(pr_description===''){
						
		this.setState({errormessage:"Please Enter  Description",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
				
				
									
			
				let strissel="1";
				if(this.state.pr_issellable===false){
				strissel="1";
  }
	
				
						var rolerowsArray = this.state.imagearr;
	 	let strdata = "",strrole="";
 var rolenewRow = rolerowsArray.map((rowss, i) => { 
 
	 
strdata=strdata+strrole+rowss.pi_id+"~"+rowss.pi_title;
strrole=";";

 })
				
				
			this.setState({errormessage:"",erropen:false,btnsavedisbaled:true,btnsavetxt:'Processing...',});
				var formData = new FormData();
				
				formData.append('action','insertorupdate')
				
				
				if(pr_id===0){
					formData.append('pr_id',"0")
					}
				else{
					
					formData.append('pr_id',pr_id)
				
				}
				formData.append('pr_images',strdata)
				
					formData.append('pr_title',pr_title)
					formData.append('pr_code',pr_code)
					formData.append('pr_ca_id',ca_mainid)
					formData.append('pr_tax',0)
					formData.append('pr_price',pr_price)
					formData.append('pr_wholesaleprice',pr_wholesaleprice)
					formData.append('pr_salesprice',pr_salesprice)
					formData.append('pr_description',pr_description)
					formData.append('pr_stockqty',pr_stockqty)
					
					formData.append('pr_issellable',strissel)
					formData.append('pr_description',pr_description)
					
					
				
				
					formData.append('userid',localStorage.getItem("ISIuserid"))
					axios.post(Serverurl+"ajaxproduct.php", formData, {
				}).then(res => {
					
						if(res.data!==0)
					{
						
					let strreturn=res.data;
					strreturn=strreturn;
					
					
					
						this.setState({redirect:true,pagename:'/AdminProduct',open:false,btnsavedisbaled:false,btnsavetxt:'Save',});
				
						
							
							
						}else{
						this.setState({btnsavedisbaled:false,btnsavetxt:'Save',errormessage:"Product Code already Exist"});
					
						}
					
					
				})
  
					
  }
  
  
  
  

	
  
  

categorybind()
{
	axios.get(Serverurl+'ajaxcategory.php?action=drop')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						categoryarray:resp.data	
							},()=>{
								
								
								   let categoryFromApi = this.state.categoryarray.map(row => {
                          
          return {value:row.ca_id, display:row.ca_title}
        });
      
		
        this.setState({
          categoryarray: [{value: '0', display: 'Select Category'}].concat(categoryFromApi)
        });
								
							}) 
			}
			
	   }) .then(data => {
     
      })

}

 	onphotoFileChange=(e) =>{
	
		 
	   let imageFile = e.target.files[0];
	   
	   if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG)$/)) {
  alert( 'Please select valid file (jpg,jpeg,png).' );
    return false;
  }else{
        this.setState({ imgCollection: e.target.files },()=>{
			
			
			 let i=0;
		let count=0;
	 var formData = new FormData();
		  
		for (const key of Object.keys(this.state.imgCollection)) {
		
            formData.append('avatar'+i, this.state.imgCollection[key])
		i++;
		count=i;
        }
	   
	formData.append('count',count)
       formData.append('action','insertorupdate')
 formData.append('pi_id','0')	
 formData.append('userid',localStorage.getItem("ISIuserid")) 
	 formData.append('pi_image','1')		
			 formData.append('pi_title','')	
			
			 formData.append('pi_pr_id',this.state.pr_id)	
			
	   	  axios.post(Serverurl+"ajaxproductimage.php", formData, {
        }).then(res => {
			
			this.onloadProductImagebyid(this.state.pr_id);
			 
            });
			
		})
		
		
		
		
		
		
  }
	
	
}

handleboxChange= (idx) => evt  => {
	var item = {    
                    id: evt.target.id,    
                    name: evt.target.name,    
                    value: evt.target.value    
                }; 
				
	const updatedArray = [...this.state.imagearr];
	
	
	if("pi_title"== item.name)
			{
	updatedArray[idx].pi_title = item.value ;
	

			}
		
			 this.setState({
        imagearr: updatedArray,
    });
}
deleteimage(pgid)
{
	
	
let r = window.confirm("Do you really want to Delete?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('pi_id',pgid)
	 formData.append('userid',localStorage.getItem("ISIuserid"))
	
	 axios.post(Serverurl+"ajaxproductimage.php", formData, {
        }).then(res => {
    
          this.onloadProductImagebyid(this.state.pr_id);
 
 
  
    
            });
 }
}

render (){
if(this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }

return(

   
	   
<React.Fragment>
 <div className="floatright">
<a href="#/Adminproduct">
  <Button  variant="contained"   className="generalbtn" >
				Back	
		</Button>
	
</a>

 
 </div>

<div className="clear"></div>
	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
			  
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>	


	<div className="adminform" >
	
	<ul>
	
	 <li>
			<p><span className="validation">*</span>Title</p>
			<p><TextField name="pr_title" 
		   onChange={this.myChangeHandler} 
			
		   fullWidth
		   size="small"
		   value={this.state.pr_title}
				
			inputProps={{tabIndex: "1",maxLength:150}}
					
			/></p>
			</li>
	
	<li>
		<p><span className="validation">*</span>Category</p>	
		<p><FormControl  className="stadrp">			   
			<Select
			inputProps={{tabIndex: "2"}}
			native
			value={this.state.ca_mainid}
			onChange={this.myChangeHandler}
			name='ca_mainid'
			
			fullwidth
			 defaultValue={{value:this.state.ca_mainid}}		
			>
		
			{this.state.categoryarray.map(row => (
				<option  
            
				  value={row.value}
				>
				  {row.display}
				</option  >
			  ))}
			 </Select>
		</FormControl></p>	
	</li>	
	
			
			
			
			
			
				<li style={{"display":"none"}}>
			<p><span className="validation">*</span>Stock</p>
			<p><TextField name="pr_stockqty" 
		   onChange={this.myChangeHandler} 
			
		   fullWidth
		   size="small"
		   value={this.state.pr_stockqty}
				
			inputProps={{tabIndex: "4",maxLength:5}}
					
			/></p>
			</li>
			
			
			
		
			
			
			
			
			
		
	
	
		
			
	
	
	 <li>
			<p><span className="validation">*</span>Product Code</p>
			<p><TextField name="pr_code" 
		   onChange={this.myChangeHandler} 
			
		   fullWidth
		   size="small"
		   value={this.state.pr_code}
				inputProps={{tabIndex: "5",maxLength:15}}
			
					
			/></p>
			</li>
			
			
			
						
		
			
		<li>
			<p><span className="validation">*</span>Distributor Price</p>
			<p><TextField name="pr_price" 
		   onChange={this.myChangeHandler} 
	
		   halfWidth
		   size="small"
		   value={this.state.pr_price}
				
						inputProps={{tabIndex: "6",maxLength:6}}
					
			/></p>
			</li>
			
			
						
	
			
			<li>
			<p><span className="validation">*</span>Wholesale Price</p>
			<p><TextField name="pr_wholesaleprice" 
		   onChange={this.myChangeHandler} 
		
		   fullWidth
		   size="small"
		   value={this.state.pr_wholesaleprice}
					inputProps={{tabIndex: "7",maxLength:6}}	
			
					
			/></p>
			</li>
		<li>
			<p><span className="validation">*</span>Retailer Price</p>
			<p><TextField name="pr_salesprice" 
		   onChange={this.myChangeHandler} 
			
		   fullWidth
		   size="small"
		   value={this.state.pr_salesprice}
				
			inputProps={{tabIndex: "8",maxLength:6}}
					
			/></p>
			</li>
			
			
			<li>
			<p>&nbsp;</p>
				<p><span className="validation">*</span>Is Sellable</p>
				<p><Checkbox
				name="pr_issellable" 
				onChange={this.handleChange}
		
				size="small"
				checked={this.state.pr_issellable}
				
				/></p>
				
			
			</li>
			
			
		<li className="stadrp">

	
<p><span className="validation">*</span>Description</p>
<p><TextField name="pr_description" 
		   onChange={this.myChangeHandler} 
			 multiline rows="3"
		   fullWidth
		  
		   value={this.state.pr_description}
				
			inputProps={{tabIndex: "9"}}
					
			/>
		    </p>


</li>
			
	<li className="stadrp"> <h3>Gallery</h3>
	<p>
	
	<Button component="label" className="primarybtn" name="imgCollection"  onChange={this.onphotoFileChange}   variant="contained"   accept="image/*;capture=camera" startIcon={<CloudUploadIcon />}>
	
		Uplaod Image
		<VisuallyHiddenInput type="file" multiple/></Button>

</p>
 <div id="galery">
 { this.state.imagearr.length>0 && (<React.Fragment>

		 <ul className="admingal">
		  {this.state.imagearr.map((item,index) => {  
           return ( <React.Fragment>
		 <li>
		 
		 <img src={Serverurl+"uploads/"+item.pi_image} width="100px" height="80px"/>
		 <div className="delicon">
		  <DeleteIcon onClick={()=>{this.deleteimage(item.pi_id)}} className="cusrsoor"/>
		  </div>
		 <p><TextField
            variant="outlined"
            margin="normal"
           label="Title"
            fullWidth value={this.state.imagearr[index].pi_title}
           id={this.state.imagearr[index].rand} 
			
              inputProps={{maxLength:150,  }} 
            name="pi_title" 
          
             onChange={this.handleboxChange(index)}
          /> </p>
		  
		 </li>
		 
		  </React.Fragment> )})}
		 </ul>
		</React.Fragment>  )}
		</div>
</li>	
			
			
			<li className="stadrp">
			<Button className="primarybtn"   onClick={this.SubmitForm} variant="contained"   disabled={this.state.btnsavedisbaled}>
				{this.state.btnsavetxt} 
		</Button>
			</li>
			</ul>
			
		
			
	<div className="clear"></div>
	
	</div>





	
		 
		
		
		
		  
	

 
		
			  
		
		
				
				
	
			 
		

      
	  
	  

		
		
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
  

</React.Fragment>

) }
 
} 
  