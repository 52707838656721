import { BrowserRouter as Router, Route, Routes,NavLink,Outlet } from 'react-router-dom';
import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import logo from './images/logo.jpg';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { styled, useTheme } from '@mui/material/styles';

import Link from '@mui/material/Link';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
const createHistory = require("history").createBrowserHistory;
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default class MiniDrawer extends Component {	
	

  constructor(props) {
    super(props)
    this.state = {

        open:false,reason:'',anchorEl:null,menuopen:null,submenuopen:false,
		network:1,name:'',redirect:false,pagename:'',mainmenu:0,year:new Date().getFullYear()}
    
    
}

componentDidMount() {

if(localStorage.getItem("YSuserid")!=null)
 {
	 //this.usernameapi();
 }

 
}	


 handledownclick = (e) => {
	 this.setState({ open:true,submenuopen:true });
 }
 
handleDrawerOpen(val){
        this.setState({ id: val });  
 // alert(val);
       

if(val===0)
{
	this.setState({ open:!this.state.open,submenuopen:true });
}
else{
	this.setState({ open:true,submenuopen:true });
	
}


      }
	  

handleDrawerClose = (e) => {
   
  this.setState({ open:!this.state.open,submenuopen:true });
//const {onToggle, setCollapsibleElement, progress } = this.state;


};

 handleClick = (e) => {
   
  this.setState({ anchorEl:e.currentTarget ,menuopen:true});
};

 handleClose = (e) => {
   
  this.setState({ anchorEl:null ,menuopen:false});
};
  

  
  
 userlogout =(anchorEl,menuopen) => (event) => {
	
	
	if(menuopen==true){
	
	let myItem = localStorage.getItem('ISIusername');
	let myItem1 = localStorage.getItem('ISIpassword');
localStorage.clear();
localStorage.setItem('ISIusername',myItem);
  localStorage.setItem('ISIpassword',myItem1);
   //location.href = "/Signin/"
	let history = createHistory();
        history.push("#");
        let pathUrl = window.location.href;
        window.location.href = pathUrl;   
	
	}
  };
	
render() {
const {onToggle, setCollapsibleElement, progress } = this.state;

  return (
   <React.Fragment> 
 
  
  
	   
	  
       <React.Fragment> 


								<div className="contentmain">
								<div className="innerpad">	  {localStorage.getItem("ISCustName")!==null && (<React.Fragment>
<div className="cusordername"><p>You are ordering for</p>
<h3>{localStorage.getItem("ISCustName")}</h3></div></React.Fragment>
)}
								  <Outlet />
								</div></div>
							






			

						


</React.Fragment>				
	



     
  <div className="footer">
		<footer>
	
	<p className="copyrighttext">©Copyright {this.state.year} Baawarchi. All rights reserved.</p>
	
	</footer>
		</div>

  </React.Fragment>  
  );
}
}
