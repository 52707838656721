import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Navigate  } from 'react-router-dom';
import Serverurl from './Apiurl';
import axios from 'axios'; 
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
export default class  Order extends Component {
	
	constructor(props) {
    super();

    this.state = {Orname:'',Oremail:'',Orphone:'',subName:'',subEmail:'',subPhone:'',Orinstruction:'',redirect:false,
	pagename:'',erroropen:false,alerttype:"error",errormessage:"",OrAddress:"",Orcity:"",Orstate:"",
	OrshipAddress:"",Orshipcity:"",Orshipstate:"",Orshippin:"",Orshipcountry:"",stendisabled:false,btnsubmit:'Submit',
	Orpin:"",ischecked:false,Orcountry:"",shipchecked:false,adresdata:[],adid:0,Ormessage:""}
	
	}
	 componentDidMount() {
		 
		if(localStorage.getItem("ISIuserid")===null)
	{
		
	
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}document.title = "Baawarchi - Checkout";
	this.Ordergetbysession();
	
 }
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  const re = /^[0-9\b]+$/;
  
  if(nam==="Orphone"  || nam==="subPhone"  )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
  }
  }
 
  else{
	  
	   this.setState({[nam]: val},()=>{
	  
	  if(nam==="adid")
	  {
		  this.SelShipAddressapicall();
		  
	  }
	  
	  
  });
  }

  
 
  
}
Ordergetbysession()
{
	axios.post(Serverurl+'ajaxorder.php?action=list&or_sessionid='+localStorage.getItem("ISIsession"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			 Orname:resp.data[0].or_name,
			  Oremail:resp.data[0].or_emailid,
			    Orphone:resp.data[0].or_phoneno,
				
				 OrAddress:resp.data[0].or_billingaddress,Orcity:resp.data[0].or_billingcity,Orstate:resp.data[0].or_bilingstate,
	Orpin:resp.data[0].or_billingpinode,subName:resp.data[0].or_submittedname,Orcountry:resp.data[0].or_country,
	subEmail:resp.data[0].or_submittedemailid,
	subPhone:resp.data[0].or_submittedphoneno,
	OrshipAddress:resp.data[0].or_shippingaddress,
			 Orshipcity:resp.data[0].or_shippingcity,
			 Orshipstate:resp.data[0].or_shippingstate,
	Orshippin:resp.data[0].or_shippingpincode,ischecked:"2",
			Orshipcountry:resp.data[0].or_shipcountry,Ormessage:resp.data[0].or_instruction
			});
			
	
     }
  else{
	  this.ApiGetCustomer();
  }
    });
}

ApiGetCustomer(){
	axios.post(Serverurl+'ajaxcustomer.php?action=list&cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			 Orname:resp.data[0].cs_name,
			  Oremail:resp.data[0].cs_email,
			    Orphone:resp.data[0].cs_phonenumber,
			});
			
		this.Addressapicall();
     }
  
    });
	
}Addressapicall(){
	
  axios.post(Serverurl+'ajaxaddress.php?action=list&ad_id='+localStorage.getItem("ISIadrsid")+'&ad_cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			 OrAddress:resp.data[0].ad_billingaddress,Orcity:resp.data[0].ad_billingcity,Orstate:resp.data[0].ad_billingstate,
	Orpin:resp.data[0].ad_billingpostalcode,ischecked:"1",Orcountry:resp.data[0].ad_billingcountry
			},()=>{ this.setState({ OrshipAddress:this.state.OrAddress,
			 Orshipcity:this.state.Orcity,
			 Orshipstate:this.state.Orstate,
	Orshippin:this.state.Orpin,
			Orshipcountry:this.state.Orcountry})});
			
		
     }
  
    });
}
ShippingAddressapicall()
{
	axios.post(Serverurl+'ajaxaddress.php?action=list&ad_cs_id='+localStorage.getItem("ISCustid"))
  
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						adresdata:resp.data	
							},()=>{  let addressFromApi = this.state.adresdata.map(row => {
                          
          return {value: row.ad_id, display:row.ad_billingname}
        });
      
		
        this.setState({
          adresdata: [{value: '0', display:'Select Address'}].concat(addressFromApi)
        });	   }) 
						
			}
			
	   }) .then(data => {
		  
       
      })

}
SelShipAddressapicall(){
	
  axios.post(Serverurl+'ajaxaddress.php?action=list&ad_id='+this.state.adid+'&ad_cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			 OrshipAddress:resp.data[0].ad_billingaddress,Orshipcity:resp.data[0].ad_billingcity,Orshipstate:resp.data[0].ad_billingstate,
	Orshippin:resp.data[0].ad_billingpostalcode,Orshipcountry:resp.data[0].ad_billingcountry
			});
			
		
     }
  
    });
}
Setshipping = (event) => 
{
	

	
	if(event.target.value==="1")
	{
		this.setState({ ischecked: event.target.value, 
			 OrshipAddress:this.state.OrAddress,
			 Orshipcity:this.state.Orcity,
			 Orshipstate:this.state.Orstate,
	Orshippin:this.state.Orpin,
	Orshipcountry:this.state.Orcountry,
			});
	}else{
		this.ShippingAddressapicall();
		this.setState({ ischecked: event.target.value, 
			 OrshipAddress:"",
			 Orshipcity:"",
			 Orshipstate:"",
	Orshippin:"",
	Orshipcountry:"",
			});
	}
};

OnclickOrderSubmit= (event) => {
	 
	   var self = this;
	   
    event.preventDefault();
		const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	 let Orname = this.state.Orname;
    let Oremail = this.state.Oremail;
	 let Orphone = this.state.Orphone;
	  let subName = this.state.subName;
	let subEmail = this.state.subEmail; 
	let subPhone = this.state.subPhone; 
	
	let  OrAddress=this.state.OrAddress,
			 Orcity=this.state.Orcity,
			 Orstate=this.state.Orstate,
	Orpin=this.state.Orpin,
	Orcountry=this.state.Orcountry,
	 OrshipAddress=this.state.OrshipAddress,
			 Orshipcity=this.state.Orshipcity,
			 Orshipstate=this.state.Orshipstate,
	Orshippin=this.state.Orshippin,
	Orshipcountry=this.state.Orshipcountry,
	Ormessage=this.state.Ormessage;
	if(Orname==="")
  {	  
    this.setState({ errormessage:'Please Enter Name',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
  if(Oremail==="")
  {	  
    this.setState({ errormessage:'Please Enter Email id',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }		 else  
  {
 if (!remail.test(Oremail)) {
	 this.setState({errormessage:"Please Enter Valid Email Id",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
  }
	  
  }
   if(Orphone==="")
  {	  
    this.setState({ errormessage:'Please Enter Phone No.',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
    if(subName==="")
  {	  
    this.setState({ errormessage:'Please Enter Order Submitted By',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  } if(subEmail==="")
  {	  
    this.setState({ errormessage:'Please Enter Order Submitted Email id',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
  
   else  
  {
 if (!remail.test(subEmail)) {
	 this.setState({errormessage:"Please Enter Valid Email Id",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
  }
	  
  }
  
  if(subPhone==="")
  {	  
    this.setState({ errormessage:'Please Enter Order Submitted Phone No.',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
    if(OrAddress==="")
  {	  
    this.setState({ errormessage:'Please Enter Address',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
  if(Orcity==="")
  {	  
   this.setState({ errormessage:'Please Enter City',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
 
  }
   if(Orstate==="")
  {	  
    this.setState({ errormessage:'Please Enter State',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
   if(Orcountry==="")
  {	  
  this.setState({ errormessage:'Please Enter Country',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })

  }
   if(Orpin==="")
  {	  
  this.setState({ errormessage:'Please Enter Postal Code',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })

  } if(OrshipAddress==="")
  {	  
    this.setState({ errormessage:'Please Enter Address',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
  if(Orshipcity==="")
  {	  
   this.setState({ errormessage:'Please Enter City',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
 
  }
   if(Orshipstate==="")
  {	  
    this.setState({ errormessage:'Please Enter State',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })
  }
   if(Orshipcountry==="")
  {	  
  this.setState({ errormessage:'Please Enter Country',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })

  }
   if(Orshippin==="")
  {	  
  this.setState({ errormessage:'Please Enter Postal Code',erroropen:true});
  return false;
   window.scrollTo({
				  top: 0
			  })

  }var formData = new FormData();
	 formData.append('action','insertorupdate') 
	  formData.append('or_cs_id',localStorage.getItem("ISCustid")) 
	   formData.append('userid',localStorage.getItem("ISIuserid"))
formData.append('or_name',Orname)
formData.append('or_emailid',Oremail)
formData.append('or_phoneno',Orphone)
formData.append('or_submittedname',subName)
formData.append('or_submittedemailid',subEmail)	
formData.append('or_submittedphoneno',subPhone)
formData.append('or_billingaddress',OrAddress)	
formData.append('or_billingcity',Orcity)
formData.append('or_shippingpincode',Orpin)	
formData.append('or_bilingstate',Orstate)
formData.append('or_country',Orcountry)	
formData.append('or_shippingaddress',OrshipAddress)	
formData.append('or_shippingcity',Orshipcity)
formData.append('or_shippingpincode',Orshippin)	
formData.append('or_shippingstate',Orshipstate)
formData.append('or_shipcountry',Orshipcountry)	
formData.append('or_sessionid',localStorage.getItem("ISIsession"))
formData.append('or_instruction',Ormessage)	
axios.post(Serverurl+"ajaxorder.php", formData, {
        }).then(res => {
			

			this.setState({ stendisabled:false,btnsubmit:"Submit",redirect:true,pagename:'/OrderConfirm'})
					
		
			

		});	
  
}
 handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }
 render() {
 
if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
	}
return (

<div className="orderform">
<Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse>
	  
<ul>
<li>
<p><span className="validation">*</span>Name</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orname}
            fullWidth
            id="Orname"   onChange={this.myChangeHandler}
          placeholder="Name"
            name="Orname" 
           inputProps={{maxLength:150, tabIndex: "1"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Email id</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Oremail}
            fullWidth
            id="Oremail"   onChange={this.myChangeHandler}
          placeholder="Email Id"
            name="Oremail" 
           inputProps={{maxLength:150, tabIndex: "2"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Phone No.</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orphone}
            fullWidth
            id="Orphone"   onChange={this.myChangeHandler}
          placeholder="Phone No."
            name="Orphone" 
           inputProps={{maxLength:15, tabIndex: "3"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Order Submitted By</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.subName}
            fullWidth
            id="subName"   onChange={this.myChangeHandler}
          placeholder="Order Submitted By"
            name="subName" 
           inputProps={{maxLength:150, tabIndex: "1"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Order Submitted Email Id</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.subEmail}
            fullWidth
            id="subEmail"   onChange={this.myChangeHandler}
          placeholder="Order Submitted Email Id"
            name="subEmail" 
           inputProps={{maxLength:150, tabIndex: "2"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Order Submitted Phone No.</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.subPhone}
            fullWidth
            id="subPhone"   onChange={this.myChangeHandler}
          placeholder="Order Submitted Phone No."
            name="subPhone" 
           inputProps={{maxLength:15, tabIndex: "3"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Address</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.OrAddress}
            fullWidth
            id="OrAddress"   onChange={this.myChangeHandler}
          placeholder="Address"
            name="OrAddress"        multiline rows="5"
           inputProps={{ tabIndex: "4"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>City</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orcity}
            fullWidth
            id="Orcity"   onChange={this.myChangeHandler}
          placeholder="City"
            name="Orcity" 
           inputProps={{maxLength:150, tabIndex: "5"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Province</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orstate}
            fullWidth
            id="Orstate"   onChange={this.myChangeHandler}
          placeholder="Province"
            name="Orstate" 
           inputProps={{maxLength:150, tabIndex: "6"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Country</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orcountry}
            fullWidth
            id="Orcountry"   onChange={this.myChangeHandler}
          placeholder="Country"
            name="Orcountry" 
           inputProps={{maxLength:150, tabIndex: "7"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Postal Code</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orpin}
            fullWidth
            id="Orpin"   onChange={this.myChangeHandler}
          placeholder="Postal Code"
            name="Orpin" 
           inputProps={{maxLength:150, tabIndex: "8"  }} 
           
          /></p>
</li>

<p>
 <FormControl>
    
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group" onChange={this.Setshipping} value={this.state.ischecked} 
      >
        <FormControlLabel value="1" control={<Radio />} label="Make Shipping Address same as billing address" />
        <FormControlLabel value="2" control={<Radio />} label="Different Shipping Address" />
     
       
      </RadioGroup>
    </FormControl>

	  </p>
	  
	  {this.state.ischecked==="2" && (
	  
	  <React.Fragment>
	<li>  <p>Select Address</p>
	  <p><FormControl  >			   
			<Select
			 onChange={this.myChangeHandler} 
		inputProps={{tabIndex: "2",}}
		   
		 	className="inputcntrl"	
			native
			value={this.state.adid}
			onChange={this.myChangeHandler}
			name='adid'
			id='id'
			fullwidth
			 defaultValue={{  value:this.state.adid }}
			InputLabelProps={{
			shrink: true,
			}}
			>
		
		
			{this.state.adresdata.map(row => (
				<option  
            
				  value={row.value}
				>
				  {row.display}
				</option  >
			  ))}
			 </Select>
		</FormControl></p></li>
	  <li><p><span className="validation">*</span>Address</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.OrshipAddress}
            fullWidth
            id="OrshipAddress"   onChange={this.myChangeHandler}
          placeholder="Address"
            name="OrshipAddress"        multiline rows="5"
           inputProps={{ tabIndex: "4"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>City</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orshipcity}
            fullWidth
            id="Orshipcity"   onChange={this.myChangeHandler}
          placeholder="City"
            name="Orshipcity" 
           inputProps={{maxLength:150, tabIndex: "5"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Province</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orshipstate}
            fullWidth
            id="Orshipstate"   onChange={this.myChangeHandler}
          placeholder="Province"
            name="Orshipstate" 
           inputProps={{maxLength:150, tabIndex: "6"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Country</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orshipcountry}
            fullWidth
            id="Orshipcountry"   onChange={this.myChangeHandler}
          placeholder="Country"
            name="Orshipcountry" 
           inputProps={{maxLength:150, tabIndex: "7"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span>Postal Code</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Orshippin}
            fullWidth
            id="Orshippin"   onChange={this.myChangeHandler}
          placeholder="Postal Code"
            name="Orshippin" 
           inputProps={{maxLength:150, tabIndex: "8"  }} 
           
          /></p>
</li>
	  </React.Fragment>
	  )}
	  <li><p>Instructions</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Ormessage}
            fullWidth
            id="Ormessage"   onChange={this.myChangeHandler}
          placeholder="Instructions"
            name="Ormessage" 
           inputProps={{tabIndex: "9"  }} 
           multiline rows="3"
          /></p>
</li><li>
	  <Button
            type="submit"
            fullWidth disabled={this.state.stendisabled}
            variant="contained" 
            className="frmPrimarybtn"  onClick={this.OnclickOrderSubmit}
            id="submitaddress" inputProps={{tabIndex: "9"  }}
         
          >
           {this.state.btnsubmit} 
          </Button></li>
</ul>
<div className="clear"></div>
</div>

)
 }
}





