import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import Serverurl from './Apiurl';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import OwlCarousel from 'react-owl-carousel';
import cart from './images/top-bar-icon-4.jpg';
import save from './images/Save.jpg';
import delet from './images/delete.jpg';
import TextField from '@mui/material/TextField';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Productactions from './Productactions';

const options1 = {
    loop: false,
  
   
    autoplay: true,
	nav:true,
	responsive : {
    // breakpoint from 0 up
    0 : {
       items:1,
       
    },
    500 : {
        items:3,
    },
    800 : {
        items:5,
    },
	1001 : {
        items:5,
    },
	}
  };
export default class  Producttitle extends Component {
	constructor(props) {
    super();

    this.state = {erroropenpop:false,errorpopmessage:"",prodopen:false,ProdpopData:[],prodimagearr:[],prodimage:"",pdid:"",popqty:1}
	  this.updateParentcart = this.updateParentcart;
	}

updateParentcart = (text) => {this.Productgetdata(this.state.pdid);
this.props.parentbasCallback(
            1+"~"+1
        );
this.setState({erroropenpop:true,errorpopmessage:"Product Added to cart"});

  }
  componentDidMount() {
  
  
  
  if(localStorage.getItem("ISIuserid")===null)
	{
		
	
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}else if(localStorage.getItem("ISIrole")!=="1")
		
		{
			this.setState({
            redirect: true,
			pagename:'Login'
          })
			
		}
	document.title = "Baawarchi - Category";
	let search = window.location.href;
	 let pdid = this.getParameterByName('pdid',search);
	if(pdid!==null)
	{
this.Productgetdata(pdid);
	}
		
}
  getParameterByName(name, url) {
	 if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " ")); 
 }
Productgetdata(pdid){
axios.get(Serverurl+'ajaxproduct.php?action=prodlist&pr_id='+pdid+'&userid='+localStorage.getItem("ISCustid")+'&sessionid='+localStorage.getItem("ISIsession"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  pdid:pdid,
			  ProdpopData: resp.data,  
			 prodimagearr:[],
			   totaldata:parseInt(resp.data[0].count)
			},()=>{this.Productgallerygetdata();});
			
			 this.props.parentbasCallback(
            resp.data[0].pr_title+"~1"
        );
	}
	
  })
}

Productgallerygetdata(){
 axios.get(Serverurl+'ajaxproductimage.php?action=list&pi_pr_id='+this.state.pdid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  prodimagearr: resp.data,  
			 prodimage:resp.data[0]["pi_image"],
			   totaldata:parseInt(resp.data[0].count)
			});
	}
	
  })
}
onclickimagechange(val){

	this.setState({prodimage  :val});
	
}
handleClose = (e) => {
	 this.setState({prodopen:!this.state.prodopen,  
	  });
}
Productchangeqty= (firstrow) => event  => { 
	 let val = event.target.value;
const updatedArray = [...this.state.ProdpopData];


		updatedArray[firstrow].qty = val;
			  

 this.setState({ProdpopData: updatedArray,popqty:val});
}

  	  handleErrorclose=(event)=>{
	  
	this.setState({erroropenpop: false});  
  }	
	render (){

return(
<React.Fragment>


   <Productactions ref="Productactions" qty={this.state.popqty}  updateDoneCart={this.updateParentcart}/>
<Collapse in={this.state.erroropenpop}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="success"
        >
        {this.state.errorpopmessage}
        </Alert>
      </Collapse>
	  <div className="prodimage">
	 {this.state.prodimage!=="" && (

 <TransformWrapper>
        <TransformComponent>
          <img src={Serverurl+"uploads/"+this.state.prodimage} alt="Baawarchi" height="300px" />
	
        </TransformComponent>
      </TransformWrapper>
	  )}
	  	 {this.state.prodimage==="" && (
		
<img src={Serverurl+"uploads/noimg.jpg"} height="300px"/>

	)}

<br/>
	
	   {this.state.prodimagearr.length>0 && (
<React.Fragment>  
    <OwlCarousel  id="car" className="owl-theme owltest" {...options1} >
{this.state.prodimagearr.map((row,i) => { 

 return ( <React.Fragment>
<div className='item' onClick={()=>{this.onclickimagechange(row.pi_image)}}>
	{row.pi_image!=="" && (   
<img src={Serverurl+"uploads/"+row.pi_image+""} />
	)}
</div>
 
</React.Fragment>	
);
})}
  </OwlCarousel>
	   </React.Fragment>)}
	   
	   </div><div className="productdetails">
	   {this.state.ProdpopData.map((row,i) => { 
	    return ( <React.Fragment>
		<h2>{row.pr_title}</h2>
		
		<h4>${row.price}</h4>
		<p><b>{row.stock}</b></p>
		  <div className="prodcart"> {row.pr_stockqty !=='0' &&(
<TextField
                               ref="qty"
                              name="qty"
							  className="counters"
                              value={row.qty}              
                                    id={this.state.ProdpopData[i].pr_id}
                           onChange={this.Productchangeqty(i)}/>
		)}	 </div>
		
		{row.pr_stockqty !=='0' &&(<React.Fragment>	
{row.ordqty ==="0" &&(

	<p className="addtocart" onClick={()=>{this.refs.Productactions.addtoCart(row.pr_id,i)}}><span className="smartadd">Add to Cart</span><span className="smartimg"><img src={cart}/></span></p>	
)}
</React.Fragment>	)}
		{row.pr_stockqty !=='0' &&(<React.Fragment>	
{row.ordqty !=="0" &&(
<React.Fragment>	
	<p className="addtocart" onClick={()=>{this.refs.Productactions.addtoCart(row.pr_id,i)}}><span className="btnupdate">Update <img src={save}/></span></p>

	&nbsp; <p className="btndelete" onClick={()=>{this.refs.Productactions.orderDelete(row.pr_id)}}>Delete <img src={delet}/></p>

	</React.Fragment>	
)}

</React.Fragment>)}
		<p>{row.pr_description}</p>
		</React.Fragment>	
);
})}
	   </div>
                     
				<div className="clear"></div>		   
</React.Fragment>

) }	
}




