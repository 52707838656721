import React ,{Component} from 'react'; 

import { Navigate   } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@mui/material/TablePagination'; 

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
const headCells = [


 
 
 
 { id:'ca_title', numeric: false, label: 'Title' },
  { id: 'maincat', numeric: false, label: 'Category' },
   { id: 'ca_icon', numeric: false, label: 'Icon' },
  { id: '', numeric: false, label: '' },
 
 
 ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 


});


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default class CategoryMaster extends Component {
	
    constructor(props) {
    super(props);
   
     this.state = {redirect: false,
     categorydata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:5,rowsPaging:[],categoryarray:[],
	 
	  order:'desc' ,orderBy:'ca_id',ca_name:'',ca_icon:'',caid:0,
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Categorydata:[]
    });
	
//this.componentDidMount();  
}
		componentWillMount() { 
localStorage.setItem("pageactive",4)
}





	componentDidMount() {
if(localStorage.getItem("ISIuserid")===null)
	{
		
	
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}else if(	localStorage.getItem("ISIrole")!=="1")
		
		{
			this.setState({
            redirect: true,
			pagename:'Login'
          })
			
		}document.title = "Baawarchi - Category";
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 

		

  


handleClickSearch=()=>{   
 
  
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	
	

 
			 axios.get(Serverurl+'ajaxcategory.php?action=getall&ca_title='+this.state.ca_name+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  categorydata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({categorydata:[],totaldata:0});
	 }
 
    });
}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{ this.apicall(this.state.page,this.state.rowsPerPage);});
   
 
   
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

    
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{this.apicall(this.state.page,this.state.rowsPerPage); }); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  },()=>{this.apicall(this.state.page,this.state.rowsPerPage); });
};





   CategoryDelete(caid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();

     formData.append('action','delete')
	 
	 formData.append('ca_id',caid)
	 
		   
formData.append('userid',localStorage.getItem("ISIuserid"))	
	
	 axios.post(Serverurl+"ajaxcategory.php", formData, {
        }).then(res => {
    
            
   this.apicall(this.state.page,this.state.rowsPerPage);
 
  
  
    
            });
 }
      }
 
  

      Categoryaddbox(val){
        this.setState({ caid: val });  

         setTimeout(() => this.refs.AddCategoryBox.handleClickOpen(),500);

      }

render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
   <React.Fragment>
   
   
   
   <div className="searchpart">
			<ul>
				
						
<li><p>Name</p>
			<p ><TextField
					
					
            halfWidth value={this.state.ca_name}
			inputProps={{tabIndex: "1",maxLength:150}}
			
           name="ca_name"  onChange={this.myChangeHandler}
			
          /></p>
	</li>
		
			
			
			
		
		<li>
		<p>&nbsp;</p>
		<p><Button className="primarybtn"  variant="contained" onClick={this.handleClickSearch}  >
			Search
			</Button></p></li>	
			
			
			<li className="floatright"><p>&nbsp;</p><p>
			  <Button variant="contained"   className="generalbtn" onClick={()=>{this.Categoryaddbox(0)}} 	 >
				New	
		</Button>
			</p>
			</li>
		</ul>

   
   </div>
   
   
   
   
 <div class="addcategorybox">


	



<AddCategoryBox ref="AddCategoryBox" id={this.state.caid}  updateDonenew={this.updateDonenewParent}/>

	
 
 </div>

  


  <TableContainer className={useStyles.container}>  
  
 
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.categorydata.length}
            />
                      <TableBody>
                     {stableSort(this.state.categorydata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.ca_title}</TableCell>
<TableCell>{item.maincat}</TableCell>

<TableCell>
{item.ca_icon!=="" && ( <React.Fragment>

<img src={Serverurl+'uploads/'+item.ca_icon} width="80px"/>
</React.Fragment>
)}
</TableCell>
<TableCell align="left">


<Button variant="contained"  className="secondarybtn"     onClick={()=>{this.Categoryaddbox(item.ca_id)}}   startIcon={<EditIcon />}>
Edit
</Button> {item.prodcnt==="0" && ( <React.Fragment>

&nbsp;<Button variant="contained"   className="generalbtn"  onClick={()=>{this.CategoryDelete(item.ca_id)}}  startIcon={<DeleteIcon />}>
  Delete
</Button> </React.Fragment>
)}
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[5,10, 20, 50,100]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}  
      />
 </React.Fragment> 

 
  );

}

}
export  class  AddCategoryBox extends Component {

   constructor(props) {
      super(props)
	  
          this.state = {redirect: false,
     categorydata:[],page: 0 ,prevpage:0,totaldata:0,
	 errormessage:"",erropen:false,name:'',year:'',title:'',alerttype:"error",
	  order:'desc' ,ca_title:'',
	       catarray:[],ca_icon:'',previocn:'',ca_mainid:'0',ca_id:'',btnsavedisbaled:false,btnsavetxt:'Save',
      }
	  
	  
	     
      
  }
  
  

  
 Categorygetdata() {
	
  if(this.props.id!==0)
  {
	  
	   axios.get(Serverurl+'ajaxcategory.php?action=getall&ca_id='+this.props.id)
  .then(resp => {  
		
    if(resp.data.length>0){
    this.setState({ 
     
  
     	ca_id:resp.data[0].ca_id,
	 ca_mainid:resp.data[0].ca_mainid,
	ca_title:resp.data[0].ca_title,
	previocn:resp.data[0].ca_icon,
  
	

    
    });
	
  }
  
});
	  
  }else{
	 this.setState({  
	  ca_title:'',
	  ca_mainid:'0',
		ca_id:0,
		ca_icon:'',
	 
	  }); 
 }
  
    }
  

handleClickOpen = (e) => {
 
  this.setState({open:!this.state.open,
     errormessage:'',erropen:false,
	  });
	     this.onloadCategoryDropdown();
		this.Categorygetdata();
};



myChangeHandler = (event) => {
	
	
  let nam = event.target.name;
  let val = event.target.value;  
 
 
  this.setState({[nam]: val});

  

}

 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }


 
  SubmitForm= (e) => {


	let ca_id = this.state.ca_id;
	let ca_mainid=this.state.ca_mainid;
	let ca_title=this.state.ca_title;
	
	
	let ca_icon=this.state.ca_icon;



this.setState({errormessage:"",erropen:false});
	if(ca_title===''){
						
		this.setState({errormessage:"Please Enter Title.",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
		
		
		

			
				
				
			if(ca_icon==='' && this.state.previocn===""){
						
		this.setState({errormessage:"Please Upload File",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
			
				}
				
			this.setState({errormessage:"",erropen:false,btnsavedisbaled:true,btnsavetxt:'Processing...',});
				var formData = new FormData();
				
				formData.append('action','insertupdate')
				
				
				if(this.props.id===0){
					formData.append('ca_id',"0")
					}
				else{
					
					formData.append('ca_id',this.props.id)
				
				}
				
					formData.append('ca_mainid',ca_mainid)
					formData.append('ca_title',ca_title)
					
					
				
			
	if(ca_icon!=="")
	  {
		
		for (const key of Object.keys(this.state.ca_icon)) {
            formData.append('Filedata', this.state.ca_icon[key])
        }
 	
	   formData.append('ca_icon','1')		
	  }else
	  {
		 formData.append('ca_icon',this.state.previocn)	  
	  
	  }
	
				
	
				
				
					formData.append('userid',localStorage.getItem("ISIuserid")) 
					axios.post(Serverurl+"ajaxcategory.php", formData, {
				}).then(res => {
					
						if(res.data!==0)
					{
						
					let strreturn=res.data;
					strreturn=strreturn;
					
					
					
					
					
					this.setState({open:false,erropen:false,btnsavedisbaled:false,btnsavetxt:'Save',alerttype:"success",});
				
						
						this.props.updateDonenew();	
							
						}else{
						this.setState({erropen:true,btnsavedisbaled:false,btnsavetxt:'Save',alerttype:"error",errormessage:"Category already exist"});
				 window.scrollTo({        top: 0     })
							
						}
					
					
				})
  
					
  }
					

		  
onloadCategoryDropdown()
{
	axios.get(Serverurl+'ajaxcategory.php?action=gettitle')
       .then(resp => { 		
			if(resp.data.length>0){
				
				
				this.setState({
						catarray:resp.data	
							},()=>{
								
								
								 let categoryFromApi = this.state.catarray.map(row => {
                          
          return {value:row.year,display:row.title}
        });
      
		
        this.setState({
          catarray: [{value: '0', display:'Select Title'}].concat(categoryFromApi)
        });
								
							}) 
			}
			
	   }) .then(data => {
		   
		  
       
      })

}

 onFileChange=(e) =>{
	   	 
	   
	
	 let imageFile = e.target.files[0];
	 
	   this.setState({ ca_icon: e.target.files })
	   
	   
	   
	   	
	
	  
  if (!imageFile.name.match(/\.(png|jpeg|jpg)$/)) {

  this.setState({errormessage:"Please Upload Valid Pic (png,jpeg,jpg).",erropen:true});
		 window.scrollTo({        top: 0     })
	
  
    return false;
  }else{
        this.setState({ ca_icon: e.target.files ,erropen:false,errormessage:""})
  }
  
		
    }

	


render (){

return(


<React.Fragment>



    <Dialog

	open={this.state.open} onClose={this.SubmitForm} maxWidth="sm" fullWidth="true" aria-labelledby="form-dialog-title">
      <div className="dialoguetitle"> 
      <DialogTitle id="form-dialog-title"> <h4>Category</h4></DialogTitle>
      <DialogContent>
	  
<div className="admindialoguform">

	<p>  <Collapse in={this.state.erropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
			  
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>					  
		<ul >
							
<li>
		<p><span className="validation">*</span>Title</p>
		<p><TextField name="ca_title" 
	   onChange={this.myChangeHandler} 
			inputProps={{tabIndex: "1",maxLength:150}}
		fullwidth
			
	  variant="outlined"
	   value={this.state.ca_title}
			 
			 
				
			/></p>
		</li>
				<li>
		<p><span className="validation">*</span>Category</p>	
		<p><FormControl  className="stadrp">			   
			<Select
			
			native
			value={this.state.ca_mainid}
			onChange={this.myChangeHandler}
			name='ca_mainid'
			
			fullwidth
			
			
			>
		
		
			{this.state.catarray.map(row => (
				<option  
            
				  value={row.value}
				>
				  {row.display}
				</option  >
			  ))}
			 </Select>
		</FormControl></p>	
	</li>		
				
					

				

<li>	
		<Button component="label" className="primarybtn" name="ca_icon"  onChange={this.onFileChange}   variant="contained"   accept="image/*;capture=camera" startIcon={<CloudUploadIcon />}>
	
		Uplaod Image
		<VisuallyHiddenInput type="file" multiple/></Button>
		{this.state.previocn!="" && (
		<a href={Serverurl+'Uploads/'+this.state.previocn} target="_blank"> View</a>
		)}
		
	</li>
		
		
	
		</ul>
		

		
		
       <div className="dialoguebtn">
			<ul>	  
		<li><Button  className="generalbtn" onClick={this.handleClickOpen} variant="contained">
					Cancel
				</Button></li>
		<li><Button className="primarybtn"  onClick={this.SubmitForm} variant="contained"  disabled={this.state.btnsavedisbaled}>
				{this.state.btnsavetxt} 
		</Button></li>
				
				
		</ul>	
			</div>     
			 
 </div> 		
 </DialogContent>
      </div>
    </Dialog>

</React.Fragment>

) }
 
} 
  