import React ,{Component} from 'react'; 
import { Navigate   } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@mui/material/TablePagination'; 
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import Autocomplete from '@mui/material/Autocomplete';

let strCat=[{title:'',year:0}];


const headCells = [


 
 
  
 { id:'pr_title', numeric: false, label: 'Title' },
   { id: 'pr_code', numeric: false, label: 'Product Code' },
  
 
   { id: 'pr_price', numeric: false, label: 'Distributor Price' },
   { id: 'pr_wholesaleprice', numeric: false, label: 'Wholesale Proce' },
   { id: 'pr_salesprice', numeric: false, label: 'Retailer Price' },
    { id: 'pr_stockqty', numeric: false, label: 'Stock' },
   { id: '', numeric: false, label: '	' },
 
 
 
 ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 


});


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default class Productmaster extends Component {
	
    constructor(props) {
    super(props);
   
     this.state = {redirect: false,
     productdata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],categoryarray:[],
	 ca_mainid:0,
	prid:'',  order:'desc' ,orderBy:'pr_id',pr_id:'',pr_code:'',pr_title:'',pr_ca_id:'',pr_tax:'',pr_price:'',pr_wholesaleprice:'',pr_salesprice:'',pr_description:'',strCatdeflt:[],autocatname:'',catarray:[],autocatname:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	
	 
	}
	
	updateDonenewParent = (text) => {  this.apicall(this.state.page,this.state.rowsPerPage);  }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:50,
	  prevpage:0,Productdata:[]
    });
	
//this.componentDidMount();  
}






	componentDidMount() {

	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 this.categorybind();
} 

		

  

categorybind()
{
	axios.get(Serverurl+'ajaxcategory.php?action=drop')
       .then(resp => { 		
			if(resp.data.length>0){
				this.setState({
						categoryarray:resp.data	
							},()=>{
								
								
								   let categoryFromApi = this.state.categoryarray.map(row => {
                          
          return {value:row.ca_id, display:row.ca_title}
        });
      
		
        this.setState({
          categoryarray: [{value: '0', display: 'Select Category'}].concat(categoryFromApi)
        });
								
							}) 
			}
			
	   }) .then(data => {
     
      })

}

handleClickSearch=()=>{   
 
  
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	
	

 
			 axios.get(Serverurl+'ajaxproduct.php?action=list&pr_code='+this.state.pr_code+'&pr_ca_id='+this.state.ca_mainid+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  productdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
     }
	 else{
		   this.setState({productdata:[],totaldata:0});
	 }
 
    });
}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);});
   
 
  
  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

   
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage);}); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};


ProductEdit(prid){ 


	localStorage.setItem("Iscaid",prid);

							
this.setState({redirect:true,pagename:'/Addproduct'});
				
       
}


   ProductDelete(prid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();

     formData.append('action','delete')
	 
	 formData.append('pr_id',prid)
	 
		   
formData.append('userid',localStorage.getItem("ISIuserid"))	
	
	 axios.post(Serverurl+"ajaxproduct.php", formData, {
        }).then(res => {
    
           this.apicall(this.state.page,this.state.rowsPerPage); 
   
   
 
    
  
    
            });
 }
      }
	  
	  
triggerimport(){
		this.refs.Importinventory.handleClickOpen();
		  
	  }
	  
	


render() {

	if(this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }



  return (
   <React.Fragment>


   <div className="searchpart">
   <Importinventory ref="Importinventory"  updateDonenew={this.updateDonenewParent}/>
			<ul>
				
						
<li><p>Title/ Product Code</p>
			<p ><TextField
					
					
            halfWidth value={this.state.pr_code}
			inputProps={{tabIndex: "1",maxLength:150}}
			
           name="pr_code"  onChange={this.myChangeHandler}
			
          /></p>
	</li>
		
	<li><p>Category</p>
				<p><FormControl  className="stadrp">			   
			<Select
			inputProps={{tabIndex: "2"}}
			native
			value={this.state.ca_mainid}
			onChange={this.myChangeHandler}
			name='ca_mainid'
			
			fullwidth
			 defaultValue={{value:this.state.ca_mainid}}		
			>
		
			{this.state.categoryarray.map(row => (
				<option  
            
				  value={row.value}
				>
				  {row.display}
				</option  >
			  ))}
			 </Select>
		</FormControl></p>
	</li>		
			
			
		
		<li>
		<p>&nbsp;</p>
		<p><Button className="primarybtn"  variant="contained" onClick={this.handleClickSearch}  >
			Search
			</Button>
			
			</p></li>	
			
			
			<li className="floatright"><p>&nbsp;</p><p>
			<a href="#Addproduct">
  <Button  variant="contained"   className="generalbtn" >
				New 	
		</Button>
		
		
	
</a>&nbsp;
  <Button  variant="contained"  

onClick={()=>{this.triggerimport()}}    className="primarybtn" >
				Import 	
		</Button>
			</p>
			</li>
			
		</ul>

   
   </div>

  <TableContainer className={useStyles.container}>  
  
 
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.productdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.productdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.pr_title}</TableCell>
<TableCell>{item.pr_code}</TableCell>

<TableCell>{item.pr_price}</TableCell>
<TableCell>{item.pr_wholesaleprice}</TableCell>
<TableCell>{item.pr_salesprice}</TableCell>
<TableCell>{item.pr_stockqty}</TableCell>

<TableCell align="left">


	



<div className="actionbtns">

<a href={'#/Addproduct?Prid='+item.pr_id}>
<Button variant="contained"  className="secondarybtn"   startIcon={<EditIcon />}>
Edit
</Button></a> &nbsp;<Button variant="contained"   className="generalbtn" onClick={()=>{this.ProductDelete(item.pr_id)}}  startIcon={<DeleteIcon />}>
  Delete
</Button> 



 
 </div>
 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
    rowsPerPageOptions={[ 20, 50,100,150,200,250,300,350,400,450,500]}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}  
      />
 </React.Fragment> 

 
  );

}

}

export  class  Importinventory extends Component {
	
	 constructor(props) {
      super(props)
      this.state = {

          open:false,imgCollection: ''
      }
      
  }
   onFileChange=(e) =>{
	      let imageFile = e.target.files[0];

	   if (!imageFile.name.match(/\.(csv)$/)) {
  alert( 'Please select valid file (csv).' );
    return false;
  }else{
        this.setState({ imgCollection: e.target.files })
  }
        
    }
handleClickOpen = (e) => {

  this.setState({ open:!this.state.open,  });
		


};
handleClose = (e) => {

 

  var formData = new FormData();

  formData.append('action','importprice')
		  //formData.append('action','import') 
		  
	  
	  if(this.state.imgCollection!='')
	  {
		  
		for (const key of Object.keys(this.state.imgCollection)) {
            formData.append('Filedata', this.state.imgCollection[key])
        } 
		
	  
	  formData.append('userid',localStorage.getItem("ISIuserid"))
	  axios.post(Serverurl+"ajaxproduct.php", formData, {
        }).then(res => {
			
			
				
					
					    alert("inventory Uploaded Successfully")
						
				
				
		

              
    
            });
  
  this.setState({ open:false });
};
}

render (){

return(
<React.Fragment>
<Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Import Products</DialogTitle>
      <DialogContent>
	  <p><a href={Serverurl+'uploads/importproductsprice.csv'} target="_blank"> Download Format</a></p>
	  <p>Import</p>
						   <p> <input type="file" name="imgCollection" onChange={this.onFileChange} /></p>
   
	   </DialogContent>
     <div className="dialoguebtn">
			<ul>	  
		<li>
        <Button className="generalbtn"  onClick={this.handleClickOpen} variant="contained">
          Cancel
        </Button></li>
		<li>
        <Button className="primarybtn" onClick={this.handleClose} variant="contained">
          Save
        </Button></li>
				
				
		</ul>	
			</div>    
     
    </Dialog>
</React.Fragment>
)
}
  
  
}