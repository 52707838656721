import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 

import Serverurl from './Apiurl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default class Adminorderproductviewmore extends Component{
	
    constructor(props) {
    super(props);
 
        this.state = {redirect: false, Orderdata:[],Proddata:[],
     
 
      }
	
	   
	}  
	componentDidMount() {
	


	 
		
	
	
	document.title = "Baawarchi - Order  Management";
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	
	
	this.ViewOrderData();
	 
  
} 
	
	
	 
	
	 Getvieworderid(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	



ViewOrderData()
{
		let search = window.location.href;
let strnoid = this.Getvieworderid('orid',search);

axios.get(Serverurl+'ajaxorder.php?action=list&or_id='+strnoid)
  .then(resp => {  
   if(resp.data.length>0){	   	
	   this.setState({Orderdata:resp.data	
	   });
	 
   this.apicall(resp.data[0]["or_sessionid"]);
	   
   }
  });
}



apicall(session){
	
 axios.get(Serverurl+"ajaxorderproduct.php?action=cartlist&sessionid="+session)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  Proddata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({Proddata:[],totaldata:0});
	 }
 
    });
}





	 
render() {
	let j=0;let totalnetamount=0; 
	return (


					
<React.Fragment>

 <div className="floatright">
<a href="#/Adminorders">
  <Button  variant="contained"   className="generalbtn" >
				Back	
		</Button>
	
</a>

 
 </div>

<div className="clear"></div>
<table width="60%">
{this.state.Orderdata.map((item,idx) => {
 return ( 	
 <React.Fragment>	
 
 <tr><td><h4><b>#{item.or_orderno} </b></h4></td> <td align="right">{item.or_createddate}</td></tr>

</React.Fragment>
);
})}</table>







 <table className="shipping-table" width="100%" cellpadding="10" cellSpacing="0">
 
{this.state.Orderdata.map((item,idx) => {
 return ( 	
 <React.Fragment>	
 
 <tr><td >
  <p>{item.or_name}<br/>
  {item.or_emailid}
  <br/>
  {item.or_phoneno}<br/> <b>How do you like to contact</b>: {item.modeofcaontact}
  </p>
  
  
</td>
<td ><div className="proddetails">
<h2>Order Submitter Details</h2>
</div>
  <p>{item.or_submittedname}<br/>
  {item.or_submittedemailid}
  <br/>
  {item.or_submittedphoneno}
  </p>
  
  
</td>
</tr>
  <tr>

</tr>
<tr><td><div className="proddetails">
<h2>Billing Address</h2>
</div><p>{item.or_billingaddress},<br/>
	{item.or_billingcity}, {item.or_bilingstate},<br/>
	
	{item.or_country} - {item.or_billingpinode}</p></td>
<td><div className="proddetails">
<h2>Shipping Address</h2>
</div><p>{item.or_shippingaddress},<br/>
	{item.or_shippingcity}, {item.or_shippingstate},<br/>
	
	{item.or_shipcountry} - {item.or_shippingpincode}</p></td>
	
	
	
	</tr>



	




</React.Fragment>


				);
					})}</table>







<div className="proddetails">
<h2>Product info</h2>
</div>

<table border="1"  cellpadding="10" cellspacing="0">
<tr>
<th>Product</th>
<th>Price</th>
<th>Quantity</th>
<th>Net Price</th>

</tr>

{this.state.Proddata.map((item,idx) => {
	
 j=idx+1; 
 totalnetamount=Number(item.netprice)+Number(totalnetamount)	
	
 return ( 	
 <React.Fragment>	




<tr>
<td>{item.op_pr_title}</td>
<td align="right">${item.op_price}</td>
<td>{item.op_quantity}</td>
<td align="right">${item.netprice}</td>

</tr>


</React.Fragment>
);
					})}
					<tr><td colSpan={2}></td><td><b>Total Price</b></td><td align="right"><b>${(totalnetamount).toFixed(2)}</b></td></tr>

</table>


</React.Fragment>





);
}	
	
	
	
}