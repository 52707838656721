import React, { Component } from "react";

import { Navigate   } from 'react-router-dom';
import Table from '@mui/material/Table';
import { makeStyles } from '@mui/styles'; 
import TableRow from '@mui/material/TableRow'; 
import TableHead from '@mui/material/TableHead'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
 import Reordericon  from './images/reorder.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TablePagination from '@mui/material/TablePagination'; 
import Serverurl from './Apiurl';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },textField: {
    width: '100%',
  },root: {  
    width: '100%',  
  },container: {  
    maxHeight: 440,  
  }, 
 
}));

export default class  MyOrder extends Component {
	constructor(props) {
    super();

    this.state = {redirect:false,pagename:'',vieworderdata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:30,rowsPaging:[],
	};
	}
	componentDidMount() {
		
		if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
           redirect: true,
			pagename:'/Login'
          })
		  }
		   localStorage.setItem("page",this.state.rowsPerPage)
		  this.apimyorder(this.state.page,this.state.rowsPerPage);
	}
	apimyorder(page,rowsPerPage)
	{
		let url="";
		
			url='&userid='+localStorage.getItem("ISIuserid")
		
		
	axios.get(Serverurl+'ajaxorder.php?action=list'+url+'&StartIndex='+page+'&PageSize='+rowsPerPage)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({vieworderdata:resp.data });	
		
	}
  })
	}
	
	
	
	handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{this.apimyorder(this.state.page,this.state.rowsPerPage);});
   
 
 
  };  
  
    handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)


    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{this.apimyorder(this.state.page,this.state.rowsPerPage);}); 
	
	
  }; 
  




onClickreorder(orid,custid){
	let r = window.confirm("Do you really want to Re-Order?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','reorder')
	 formData.append('or_id',orid)
	  formData.append('or_sessionid',localStorage.getItem("ISIsession"))
formData.append('userid',custid)	
	
	
	 axios.post(Serverurl+"ajaxorder.php", formData, {
        }).then(res => {
    
           
    this.setState({
           redirect: true,
			pagename:'/Mycart'
          })
    
            });
 }
}




	
	render() {



if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	
return (
 <React.Fragment>

  <style>{"\
        .cusordername{display:none;}\
      "}</style>




					
 
  {this.state.vieworderdata.length===0 && (
  <h3 className="aligncentr"><a href="#/product"> We noticed you haven't placed any orders with us. Please click here to proceed with ordering</a></h3>
  )}
<TableContainer className="ordertab">  
   <Table stickyHeader aria-label="sticky table" id="box" width="70%"> 
 
    <TableBody> 

{this.state.vieworderdata.map((item,idx) => {
 return ( 	
 <TableRow id="addr0" key={idx}>
 <TableCell >
  </TableCell> 
  <TableCell><h4>#{item.or_orderno}</h4>
<p>Date:{item.or_createddate}</p>

  </TableCell>  <TableCell >  </TableCell> 
  <TableCell><h2>{item.or_name}</h2>
  </TableCell> <TableCell >  </TableCell> 
  <TableCell><h3>Total :<b> ${item.total}</b></h3>
<p className="reodrerbtn" onClick={()=>{this.onClickreorder(item.or_id,item.or_cs_id)}}><img src={Reordericon} width="25px" className="iconvertical"/>&nbsp;Reorder</p>

  </TableCell> 
<TableCell align="right">
  </TableCell> 
<TableCell align="right">
	<a href={"#/Orderdetails?orid="+item.or_id}> <span><ArrowForwardIcon/></span></a>
  </TableCell> 


 </TableRow>
)
})}
 </TableBody> 
</Table>
</TableContainer>
<TablePagination  
        rowsPerPageOptions={this.state.rowsPaging}  
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onChangePage={this.handleChangePage}  
        onChangeRowsPerPage={this.handleChangeRowsPerPage}  
      />  


</React.Fragment>

)
	
	
	
}
	 
}


