import React ,{Component} from 'react';
import { makeStyles } from '@mui/styles';  
import { Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import PropTypes from 'prop-types';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ordericon from './images/create-order-icon.jpg';
import save from './images/Save.jpg';
import delet from './images/delete.jpg';
import view from './images/view.jpg';
import TextField from '@mui/material/TextField';
import cart from './images/top-bar-icon-4.jpg';
import Table from '@mui/material/Table';
import Generatesession from './GenerateSession';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Cart extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',CartData:[],qty:1,alerttype:'error',errormessage:'',erroropen:false,totalcount:0}
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	document.title = "Baawarchi - My Cart";
	
	
 
  this.ApiOrderProduct(); 

}



ApiOrderProduct(){
	
	 axios.get(Serverurl+"ajaxorderproduct.php?action=cartlist&sessionid="+localStorage.getItem("ISIsession"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  CartData: resp.data,  
			  qty: resp.data[0].qty,
			  totalcount:resp.data[0].totalcount,
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }else{
		this.setState({   CartData:[],totalcount:0,totaldata:0});
	 }

    });
	
	
}
 orderDelete(prid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('pr_id',prid)
	  formData.append('sessionid',localStorage.getItem("ISIsession"))
formData.append('userid',localStorage.getItem("ISIuserid"))	
	
	
	 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
     let strqty=parseFloat(localStorage.getItem("IScartqty"))-parseFloat(1);
			  localStorage.setItem("IScartqty",strqty)
			  this.props.parentcartCallback(
            strqty
        );
            this.ApiOrderProduct();
   
    
            });
 }
      } 

addtoCarts(id,firstrow){
	
	
	const updatedArray = [...this.state.CartData];
	let qty=updatedArray[firstrow].op_quantity;

	var formData = new FormData();
	  formData.append('action','Create')
	  
	  formData.append('op_pr_id',id)
	   formData.append('op_sessionid',localStorage.getItem("ISIsession"))
	 formData.append('op_quantity',qty)
	 
	    formData.append('userid',localStorage.getItem("ISCustid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
			
			
   this.setState({alerttype:'success', errormessage:'Product Updated to Cart',erroropen:true},()=>{ this.ApiOrderProduct();}); 
             window.scrollTo({
				  top: 0
			  })
    
            });		
	
}
Productqty= (firstrow) => event  => { 
	 let val = event.target.value;
const updatedArray = [...this.state.CartData];


		updatedArray[firstrow].op_quantity = val;
			  

 this.setState({CartData: updatedArray,});
}
  handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }
render() {
	
if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }
  
  	let j=0;let totaltnetamount=0; 
    
  return (
  

   
 
  
    <React.Fragment>
     

   <Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse>


{this.state.totalcount ===0 &&(<React.Fragment>  
<h3 className="aligncentr">Your Cart is Empty</h3>
</React.Fragment> )}
 {this.state.totalcount !==0 &&(<React.Fragment>  
 
<table width="80%" className="catable"> 
		        
<tr><td colSpan={2}></td><td align="right">Price</td><td align="right">Net Price</td></tr>
<tr>
					 <td colSpan={4}><hr/></td>
					 </tr>
                     {this.state.CartData.map((item,index) => {  
					 j=index+1; 
 totaltnetamount=parseFloat(item.netprice)+parseFloat(totaltnetamount)
           return ( <React.Fragment>

<tr >   
               
			
            
	
           
	<td>
	<div className="cartprodimage">
	{item.prdimg!=="" && (	<img src={Serverurl+"uploads/"+item.prdimg+""} />
	)}
{item.prdimg==="" && (
<img src={Serverurl+"uploads/noimg.jpg"} />
	)}
</div>		<div className="cartprodtitle">
		<h4>{item.op_pr_title}</h4>	
		</div>
		<div className="cleat"></div>
	</td>
	           
	<td className="prodcart">
	<div className="cartprodqty">
	<input id="op_quantity" name="op_quantity"  value={item.op_quantity} 
 className="cartcounters"	onChange={this.Productqty(index)}  / >

							</div>
							<div className="cartprodqtybtn">
								<p className="primarybtn" onClick={()=>{this.addtoCarts(item.op_pr_id,index)}}>Update{<DoneIcon className="iconvertical"/>}</p>
<br/>	
	<p className="secondarybtn" onClick={()=>{this.orderDelete(item.op_pr_id)}}>Delete{<DeleteIcon className="iconvertical"/>}</p>	
	</div><div className="cleat"></div>
	</td>
<td align="right">$ {item.op_price}</td>
<td align="right">$ {item.netprice}</td>
			   
		   </tr> 
			  












				</React.Fragment>	
				);
					})}
                     <tr>
					 <td colSpan={4}><hr/></td>
					 </tr>
					 <tr><td colSpan={2}></td><td>Subtotal({this.state.totalcount} items)</td><td align="right" className="nettot"><b >${(totaltnetamount).toFixed(2)}</b></td></tr>
                 <tr>
					 <td colSpan={4}><hr/></td>
					 </tr>
					  <tr>
					 <td colSpan={4} align="right"><p  className="primarybtn"><a href="#/UserAddresslist" >Proceed to Checkout</a></p></td>
					 </tr>
 </table>
 
  <div className="clear"></div>   
</React.Fragment>)}

 
 </React.Fragment>
		
	  
	 


  );
  }	
   
   


}