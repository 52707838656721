import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import settings from './images/top-bar-icon-3.jpg';
import back from './images/arrow-back.jpg';
import cart from './images/top-bar-icon-4.jpg';
import save from './images/Save.jpg';
import delet from './images/delete.jpg';
import view from './images/view.jpg';
import Productpop from './Productpop';
import Autocomplete from '@mui/material/Autocomplete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Addcart from './images/cart-icon.jpg';
import ListIcon from '@mui/icons-material/List';
import Generatesession from './GenerateSession';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
let strProd=[{title:'',year:0}];
let strCat=[{title:'',year:0}];
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});

export default class Product extends Component {
	
    constructor(props) {
    super(props);
   
      this.state = {redirect: false,SubCatData:[],SearchData:[],alerttype:'error',prodid:0,
      CatData:[],caid:'',cattitles:'',searchcategory:'',mainid:0,open:true,erroropen:false,errormessage:'',
	  erroropen1:false,errormessage1:'',catTitehead:'',page:0,rowsPerPage:30,transform:0,   
	  formtypes:1,ProdData:[],prodtitle:'',qty:1,distypes:1,startindex:0,busy:1,key:'',currentindex:0,autoCatname:'',cattags:'',strCatdeflt:[],hideNav:0,hideprod:false,hidecatmob:false,
	      autoProdname:'',prodtags:'',strProddeflt:[],mainprocat:0    
      }
	   this.onProdTagsChange = this.onProdTagsChange.bind(this);
	  this.oncatTagsChange = this.oncatTagsChange.bind(this);
       this.updateDonenewParent = this.updateDonenewParent;
	   this.escCatFunction= this.escCatFunction;
	   	   this.escProdFunction= this.escProdFunction;
	   	  this.updateParentProduct = this.updateParentProduct;
	}
	
	updateDonenewParent = (text) => {this.componentWillMount();  this.props.parentCallback(
            1
        );  }
	
	 updateParentProduct = (text) => {this.componentWillMount();  this.props.parentCallback(
            1
        );  }


handleScroll= (event) => {
    let scrollTop = event.srcElement.body.scrollTop,
        itemTranslate = Math.min(0, scrollTop/3 - 60);
let strbusy=this.state.busy;
if(strbusy===0){
    this.setState({
      transform: itemTranslate,busy:1
    }, () => {

		   
 	
	
	this.apicallprod();
	
	   
});  
}
}
 onInputChange = (event, newTextValue) => {

if(newTextValue!==""){


  this.setState({autoCatname:newTextValue})
}
 
 }
 onloadCategoryAutoBind(){
	 
	  

	    axios.get(Serverurl+'ajaxcategory.php?action=catsearchlist&ca_mainid='+this.state.mainid)
  
  .then(resp => { 
 this.setState({strCatdeflt:[]},()=>{
	 
	 var rowsArray = resp.data;
	 rowsArray.map((row, i) => {
			 
		 const items = {
        title:row.ca_title,
          year:row.ca_id
          
        };
		
         strCat.push(items)
		 
		 });
		  this.setState({strCatdeflt:strCat},()=>{console.log(this.state.strCatdeflt)})
	 
 })
  
     this.onloadProdAutoBind();
   });
  
  }
 oncatTagsChange = (event, values) => {
	 console.log(values)
if(values!==null){
    this.setState({
      cattags: values["year"]
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
}else{
	this.setState({cattags:'',autoCatname:'',cattitles:'' },()=>{this.searchcategory();});
}
  }
  
  onProdInputChange = (event, newTextValue) => {

if(newTextValue!==""){


  this.setState({autoProdname:newTextValue})
}
 
 }
 onloadProdAutoBind(){
	 
	  

	    axios.get(Serverurl+'ajaxproduct.php?action=prodlist&userid='+localStorage.getItem("ISCustid")+'&sessionid='+localStorage.getItem("ISIsession"))
  
  .then(resp => { 
 this.setState({strProddeflt:[]},()=>{
	 
	 var rowsArray = resp.data;
	 rowsArray.map((row, i) => {
			 
		 const items = {
        title:row.pr_title,
          year:row.pr_id
          
        };
		
         strProd.push(items)
		 
		 });
		  this.setState({strProddeflt:strProd})
	 
 })
  
   
   });
  
  }
 onProdTagsChange = (event, values) => {
	
if(values!==null){
    this.setState({
      prodtags: values["year"],mainid:0,
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
}else{
	this.setState({prodtags:'',autoProdname:'',prodtitle:'', },()=>{this.apicallprod();});
}
  }
	componentWillMount() {
		
	document.title = "Baawarchi - Products";
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
		   if(localStorage.getItem("ISIsession")==null){
			   let strguid = Generatesession();
			
			 localStorage.setItem("ISIsession",strguid);
		 }
	
	document.addEventListener("keydown", this.escProdFunction, false);
	document.addEventListener("keydown", this.escCatFunction, false);
 window.addEventListener('scroll', this.handleScroll);
  window.addEventListener("resize", this.resize.bind(this));
    this.resize();
 this.searchcategory();
 this.onloadCategoryAutoBind();

} 


componentWillUnmount(){
    document.removeEventListener("keydown", this.escCatFunction, false);
		document.addEventListener("keydown", this.escProdFunction, false);
		  window.removeEventListener('scroll',  this.handleScroll);
		
     window.removeEventListener("resize", this.resize.bind(this));
  }
  
  resize() {
	 
    this.setState({hideNav: window.innerWidth <= 650,hidecatmob:false},()=>{
		if(this.state.hideNav===true)
		{
			 this.setState({hideprod: true});
		}
		
		else{
			 this.setState({hideprod: false});
		}
		
	});
}
 escCatFunction= (event) => {
	  var self=this; 
	   let nam = event.target.name;
        let val = event.target.value;
		if(nam=="cattitles")
		{
			
 this.setState({[nam]: val,mainid:this.state.mainid,catTitehead:''});
 
  if(event.keyCode === 13) {
	 
	  if(val==="")
	  {
				  this.setState({errormessage:"Please Enter Category",currentindex:0,erroropen:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
	  }
	  else{
		 
		  this.setState({errormessage:"",currentindex:0,erroropen:false},()=>{
			  setTimeout(() => this.searchcategory(),500);
			  
		  });
				
	 	

	  }		
  }
		} 
 }
 Productaddbox(val){
        this.setState({ prodid: val });  
  
         setTimeout(() => this.refs.Productpop.handleClickOpen(),500);

      }
handleClickSearch=()=>{   
 
  if(this.state.cattitles==="")
	  {
				  this.setState({errormessage:"Please Enter Category",erroropen:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
	  }
  this.searchcategory();
  

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}
handleCategoryback = (e) => {
				this.setState({cattitles:"",hidecatmob:false,mainid:0,catTitehead:''},()=>{  this.searchcategory();});
				
		};
searchcategory(){
	let url='&ca_mainid='+this.state.mainid;
	if(this.state.cattitles!="")
	{
		url='&ca_title='+this.state.cattitles+'';
	}
	  axios.get(Serverurl+'ajaxcategory.php?action=catsearchlist'+url)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  CatData: resp.data,ProdData:[],			 
			   totaldata:parseInt(resp.data[0].count)
			},()=>{
				
				
				
			});
			
	  
	 
     }else{
		this.setState({  
			  CatData: [],ProdData:[],			 
			   totaldata:0,
			},()=>{});
			 
	 }
this.apicallprod();
    });
	
}
 onClickCategory(val,maincat) {					
				if(this.state.mainid===0){
				 this.setState({mainid: val,startindex:0,currentindex:0,catTitehead:maincat,ProdData:[],cattitles:'',cattags:'',autoCatname:''},()=>{this.searchcategory();this.onloadCategoryAutoBind();});				 
				 
				}else{
					this.setState({mainid: val,startindex:0,currentindex:0,catTitehead:maincat,ProdData:[],cattitles:'',cattags:'',autoCatname:''},()=>{this.apicallprod();this.onloadCategoryAutoBind();
					
					if(this.state.hideNav===true)
		{
			 this.setState({hideprod: false,hidecatmob:true});
		}
					});			
				}

}; 



handleNext(val) {
					
				
				 this.setState({formtypes:"2",caid: val});
				
				 
	this.apicallSubcat();
this.apicallprod();	

};	
handlelist() {
					
				
				 this.setState({distypes:"2"});
				

};
handlegrid() {
					
				
				 this.setState({distypes:"1"});
				

};
   
escProdFunction= (event) => {
	  var self=this; 
	   let nam = event.target.name;
        let val = event.target.value;
	if(nam=="prodtitle")
		{
 this.setState({[nam]: val,});

  if(event.keyCode === 13) {
	 
	  if(val==="")
	  {
				  this.setState({ProdData:[],startindex:0,currentindex:0,errormessage1:"Please Enter Product",erroropen1:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
	  }else{
		  
		   this.setState({ProdData:[],startindex:0,currentindex:0,errormessage1:"",erroropen1:false},()=>{
			    	setTimeout(() => this.apicallprod(),500);
			   
		   }
		   );
	
	  }		
  }
		}  
 }
		
		
apicallprod(){
	  axios.get(Serverurl+'ajaxproduct.php?action=prodlist&ca_id='+this.state.mainid+'&pr_title='+this.state.prodtitle+'&userid='+localStorage.getItem("ISCustid")+'&sessionid='+localStorage.getItem("ISIsession")+'&index='+this.state.startindex+'&maxrecrd=12')
  .then(resp => {  
		
    if(resp.data.length>0){
		let curentindex=parseFloat(this.state.currentindex)+parseFloat(resp.data.length);
			this.setState({  
			  ProdData:this.state.ProdData.concat(resp.data), currentindex:curentindex, 
			 
			   totaldata:parseInt(resp.data[0].count)
			});
			
			if(resp.data[0].count>curentindex)
		{
			 this.setState({busy:0,startindex:Number(this.state.startindex)+1});
			
		}
		else if(resp.data[0].count==curentindex)
		{
			 this.setState({busy:1});
			
		}
	  
	 
     }else{
		this.setState({  
			  ProdData:[],  
			 
			   totaldata:0
			}); 
	 }

    });
	
}
handleSearch=()=>{   
 if(this.state.prodtitle==="")
	  {
				  this.setState({errormessage1:"Please Enter Product",erroropen1:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
	  }
  this.apicallprod();
  

	
}


ChangeProductqty= (firstrow) => event  => { 
	 let val = event.target.value;

const updatedArray = [...this.state.ProdData];


		updatedArray[firstrow].qty = val;
			  

 this.setState({ProdData: updatedArray,});
}

handleDecrement = (id,rowsd) => {
	let qty;
        const updatedArray = [...this.state.ProdData];
        this.setState(prevState => ({
        items: prevState.ProdData.filter(item =>  {
			if(id===item.pr_id)
			{
                qty=updatedArray[rowsd].qty - 1;
                    return true;    
              }  })
            }));
    }
    handleIncrement = (id,rowsd) => {
		let qty;
		const updatedArray = [...this.state.ProdData];
        this.setState(prevState => ({
        items: prevState.ProdData.filter(item =>  {
			if(id===item.pr_id)
			{
                qty =updatedArray[rowsd].qty + 1;
                    return true;    
              }  })
            }));		
	
    }
     

addtoCart(id,firstrow){
	
	const updatedArray = [...this.state.ProdData];
	let qty=updatedArray[firstrow].qty;

	var formData = new FormData();
	  formData.append('action','Create')
	  
	  formData.append('op_pr_id',id)
	   formData.append('op_sessionid',localStorage.getItem("ISIsession"))
	 formData.append('op_quantity',qty)
	 
	    formData.append('userid',localStorage.getItem("ISCustid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
			
			
   this.setState({alerttype:'success',ProdData:[],mainid: this.state.mainid,startindex:0, errormessage1:'Product Added to Cart',erroropen1:true},()=>{ this.apicallprod();}); 
             window.scrollTo({
				  top: 0
			  })
			 
			  let strqty=parseFloat(localStorage.getItem("IScartqty"))+parseFloat(1);
			  localStorage.setItem("IScartqty",strqty)
   // window.location.href=window.location.href;
    this.props.parentCallback(
            strqty
        );
            });		
	
}


orderDelete(prid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('pr_id',prid)
	  formData.append('sessionid',localStorage.getItem("ISIsession"))
formData.append('userid',localStorage.getItem("ISIuserid"))	
	
	
	 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
     let strqty=parseFloat(localStorage.getItem("IScartqty"))-parseFloat(1);
			  localStorage.setItem("IScartqty",strqty)
			  this.props.parentCallback(
            strqty
        );
         this.setState({alerttype:'info',ProdData:[],mainid: this.state.mainid,startindex:0, errormessage1:'Product has been deleted from cart',erroropen1:true},()=>{ this.apicallprod();}); 
         
    window.scrollTo({
				  top: 0
			  })
    
            });
 }
      }
	  handleErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }	
  
  	  handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen1: false});  
  }	
  
/*

<TextField
            variant="standard"
            margin="normal"
            fullWidth value={this.state.cattitles}
            id="titles"
           
            name="cattitles" 
           onChange={this.escCatFunction}
onFocus={this.escCatFunction}
           
          disableunderline={true} placeholder="Search Category" InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon onClick={this.handleClickSearch}/>
                    </IconButton>
                  ),
                }}/>


  
  <TextField  variant="standard" margin="normal"    fullWidth value={this.state.prodtitle} id="prodtitle"   name="prodtitle"           onChange={this.escProdFunction}
onFocus={this.escProdFunction}   disableunderline={true} placeholder="Search Products" InputProps={{                  endAdornment: (
                    <IconButton>
                      <SearchIcon onClick={this.handleSearch}/>
                    </IconButton>
                  ),
                }}/>
				*/
render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
  
  
  
  <div className="producttabrow">


    
  <Productpop ref="Productpop" pdid={this.state.prodid}   updateDoneprod={this.updateParentProduct}/>
  
   <div className="productrow">

<div className="categorysection"><div className="catinnerpad">

  <div className="searchsec">
  {this.state.errormessage!=="" && (
   <p>  <Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
  )}
<p>
{this.state.mainid!==0 && (

<ArrowBackIcon onClick={this.handleCategoryback} className="iconvertical catback"/>
)}{this.state.mainid===0 && (
<span style={{"width":"24px","visibility":"hidden"}}><ArrowBackIcon onClick={this.handleCategoryback} className="iconvertical catback"/></span>
)}

 <Autocomplete
       defaultValue={this.state.strCatdeflt}
	    inputValue={this.state.autoCatname}
		  onInputChange={this.onInputChange}
         id="catauto"
      
        options={strCat}

		 value={this.state.cattags}
 

    
	  onChange={this.oncatTagsChange}  
        renderInput={params => (
        <TextField {...params}   name="cattitles"   variant="outlined" fullWidth
placeholder="Search Category"
onFocus={this.escCatFunction}
		  />
      
        )}
		getOptionLabel={(option) => option.title || ""}
	
      />
	  
	  
	  <div className="clear"></div>
</p>
				</div>	



				{this.state.hidecatmob===false && (
				
				
<div className="catsec">

 {this.state.formtypes == "1" &&(<React.Fragment>
 
{this.state.CatData.length===0 && (
<h3>No Records</h3>
)}
	<ul>	        

                     {this.state.CatData.map((item,index) => {  
           return ( <React.Fragment>


		  
<li onClick={()=>{this.onClickCategory(item.ca_id,item.ca_title)}}>
		<div className="catboxpad">
<div className="catcount"><p>{item.prodcount}</p></div>
	{item.ca_icon!=="" && (
<img src={Serverurl+"uploads/"+item.ca_icon+""} />
	)}
		{item.ca_icon==="" && (
<img src={Serverurl+"uploads/noimg.jpg"} />
	)}
<h4>{item.ca_title}</h4>

</div>
</li>  	
			








				</React.Fragment>	
				);
					})}
                     

                   
 </ul> 

 </React.Fragment>)}
 

 </div>
 )}
 </div>
</div>

<div className="Productsection">
{this.state.hideprod===false && (
<div className="prodinnerpad">

   <Collapse in={this.state.erroropen1}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage1}
        </Alert>
      </Collapse>
	  {localStorage.getItem("ISCustName")!==null && (<React.Fragment>
<div className="cusordername"><p>You are ordering for</p>
<h3>{localStorage.getItem("ISCustName")}</h3></div></React.Fragment>
)}
<div className="prodheading">


{this.state.catTitehead!="" && (

<h2>{this.state.catTitehead}</h2>
)}
</div>
<div className="searchlrt">				
<p>	<GridViewIcon className="gridicon cusrsoor" onClick={()=>{this.handlegrid()}}/> &nbsp; <FormatListBulletedIcon className="gridicon cusrsoor" onClick={()=>{this.handlelist()}}/></p>
				</div>
<div className="searchlft">
<div className="searchsec">


<p> 
 <Autocomplete
       defaultValue={this.state.strProddeflt}
	    inputValue={this.state.autoProdname}
		  onInputChange={this.onProdInputChange}
         id="catauto"
      
        options={strProd}

		 value={this.state.prodtags}
 

    
	  onChange={this.onProdTagsChange}  
        renderInput={params => (
        <TextField {...params}   name="prodtitle"   variant="outlined" fullWidth
placeholder="Search Products"
onFocus={this.escProdFunction}
		  />
      
        )}  getOptionLabel={(option) => option.title || ""}
	
      />
</p>
				
				
				</div>
				</div>

	<div className="clear">	</div>			
			


<div >


{this.state.ProdData.length===0 && (
<h3>No Records</h3>
)}
 

<ul>
       

                     {this.state.ProdData.map((item,index) => { 

let i= parseFloat(((this.state.page)+1)-1)*parseFloat(this.state.rowsPerPage)+(parseFloat(index)+1);					 
           return ( <React.Fragment>
		   {this.state.distypes == "1" &&(

<li className="products gridprod" key={i}>
{item.stock ==='Out of Stock' &&(<React.Fragment><p className="bgclr">{item.stock}</p>
</React.Fragment>)}
{item.stock !=='Out of Stock' &&(<React.Fragment>
<p className="bgclrs">{item.stock}</p>
</React.Fragment>)}
<div className="prodimg"><a href={"#/Productview?pdid="+item.pr_id}>{item.prdimg!=="" && (
<img src={Serverurl+"uploads/"+item.prdimg+""}   />
	)}
	{item.prdimg==="" && (
<img src={Serverurl+"uploads/noimg.jpg"} />
	)}
	</a>
<h2><a href={"#/Productview?pdid="+item.pr_id}>{item.prodtitle}</a></h2></div>
<div className="prodinfo">
<h4>$ {item.price}</h4>
{item.pr_stockqty !=='0' &&(<React.Fragment> 
 
                 <div className="prodcart">
  

 <TextField
                               ref="qty"
                              name="qty"
							  className="counters"
                              value={item.qty}              
                                   id={this.state.ProdData.pr_id}
                            onChange={this.ChangeProductqty(index)}/>
 
 
 </div>
              

{item.ordqty !=="0" &&(
<ul className="innericons">

<li onClick={()=>{this.addtoCart(item.pr_id,index)}}><img src={save} />
<p>Update</p>
</li>
<li onClick={()=>{this.orderDelete(item.pr_id)}}><img src={delet} />
<p>Delete</p></li>
<li onClick={()=>{this.Productaddbox(item.pr_id)}}><img src={view}/><p> View</p></li>

</ul>
)}
{item.ordqty ==="0" &&(
<ul className="carticons">

<li onClick={()=>{this.addtoCart(item.pr_id,index)}}><img src={Addcart} />
<p>Add to Cart</p>
</li>

<li onClick={()=>{this.Productaddbox(item.pr_id)}} className="cusrsoor"><img src={view}/><p>Quick View</p></li>

</ul>
)}
</React.Fragment>)}
{item.pr_stockqty ==='0' &&(<React.Fragment> 

<ul className="carticons nostock">
<li onClick={()=>{this.Productaddbox(item.pr_id)}} className="cusrsoor"><img src={view}/><p>Quick View</p></li>

</ul>

</React.Fragment>)}
</div>
</li>

		   )}{this.state.distypes === "2" &&(

<li className="listtabrow">   
         <div className="listprodrow">	      
		<div className="prodcol1">	
            
	
           
		

	<div className="prodlistimg prodimg">
		<div className="prodcount bgclrs">
	{item.stock ==='Out of Stock' &&(<React.Fragment>{item.stock}
</React.Fragment>)}
{item.stock !=='Out of Stock' &&(<React.Fragment>
{item.stock}
</React.Fragment>)}
	</div>{item.prdimg!=="" && (
	<a href={"#/Productview?pdid="+item.pr_id}><img src={Serverurl+"uploads/"+item.prdimg+""} width="100px" height="150px"  />
	</a>
	)}
{item.prdimg==="" && (<a href={"#/Productview?pdid="+item.pr_id}>
<img src={Serverurl+"uploads/noimg.jpg"} />
</a>
	)}
</div><div className="prodlistheading">
<h2><a href={"#/Productview?pdid="+item.pr_id}>{item.prodtitle}</a></h2>
	</div>
	
	<div className="clear"></div>
	</div>
	           
	<div className="prodcol2"><b>${item.price}</b>			
	</div>
	  <div className="prodcol3 prodcart"> {item.pr_stockqty !=='0' &&(
<TextField
                               ref="qty"
                              name="qty"
							  className="counters"
                              value={item.qty}              
                                    id={this.state.ProdData.pr_id}
                           onChange={this.ChangeProductqty(index)}/>
 

	           



		)}	 </div>

<div className="prodcol4">{item.pr_stockqty !=='0' &&(<React.Fragment>	
{item.ordqty ==="0" &&(

	<p className="addtocart" onClick={()=>{this.addtoCart(item.pr_id,index)}}><span className="smartadd">Add to Cart</span><span className="smartimg"><img src={cart}/></span></p>	
)}
</React.Fragment>	)}


{item.pr_stockqty !=='0' &&(<React.Fragment>	
{item.ordqty !=="0" &&(
<React.Fragment>	
	<p className="addtocart" onClick={()=>{this.addtoCart(item.pr_id,index)}}><span className="btnupdate">Update <img src={save}/></span></p>

	&nbsp; <p className="btndelete" onClick={()=>{this.orderDelete(item.pr_id)}}>Delete <img src={delet}/></p>

	</React.Fragment>	
)}
</React.Fragment>	)}

&nbsp; <img src={view} className='listquickview cusrsoor' onClick={()=>{this.Productaddbox(item.pr_id)}}/>
		 </div>
		  </div> </li> 


)}
				</React.Fragment>	
				);
					})}
                   
</ul>


   </div>

	
	
	
	
	


</div>)}</div>

</div>
</div>
 
 
  );
}
}



  