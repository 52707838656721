import React, { Component } from "react";
import Serverurl from './Apiurl';
import axios from 'axios'; 

import { makeStyles } from '@mui/styles'; 

import clsx from 'clsx';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate  } from 'react-router-dom';

export default class  Product extends Component {
	
	 constructor(props) {
      super(props)
      this.state = {data:[],shipdata:[],strtype:0,redirect:false,pagename:'',stendisabled:false,btnsubmit:"Deliver To this Address",radiodefaultValue:0,shipValue:0,redirectdate:false}
	
     }
	 componentDidMount() {
		 document.title = "Baawarchi Checkout";
		 if(localStorage.getItem("ISIuserid")!==null)
	{
		
	}else{
		this.setState({
            redirect: true,
			pagename:'Login'
          })
		 
	}
	  let search = window.location.href;
let strtype = this.Getvieworderid('type',search);

if(strtype==="1")
{
			 this.BillingAddressapicall(); 
}
else if(strtype==="2")
{
	this.ShippingAddressapicall();
}
		 
	 }

	 Getvieworderid(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	 
 BillingAddressapicall(){
	
  axios.post(Serverurl+'ajaxaddress.php?action=list&ad_type=1,3&ad_cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			  data: resp.data,strtype:1,  
			  radiodefaultValue: resp.data[0].ad_id
			});
			
		
     }else{
		 this.setState({  
			  data: [],strtype:1,  
			  radiodefaultValue: 0
			});
	 }
  
    });
}
 ShippingAddressapicall(){
	
  axios.post(Serverurl+'ajaxaddress.php?action=list&ad_type=2,3&ad_cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			  shipdata: resp.data,strtype:2,  
			  shipValue: resp.data[0].ad_id
			});
			
		
     }else{
		 this.setState({  
			  shipdata: [],strtype:2,  
			  shipValue: 0
			});
	 }
  
    });
}
selectaddress= (daid) => {
    

	this.setState({radiodefaultValue: daid})
}

triggerNewaddres= (event) => {
    
   
	this.setState({redirect: true,pagename:'/MyAddressEntry'})
}
Changeshipaddress= (event) => {
	
	this.setState({redirect:true,pagename:'/MyAddressEntry?type=2'});
	
}
triggerdelete=(daid) => {
		let r = window.confirm("Do you really want to Delete?");
 if(r == true){
	 var formData = new FormData();
	  formData.append('action','Delete') 
	  formData.append('ad_id',daid)
	 formData.append('userid',localStorage.getItem("ISIuserid"))
		 axios.post(Serverurl+"ajaxaddress.php", formData, {
        }).then(res => {
   
            alert("Deleted Successfully")
     this.componentDidMount(); 
            });	
 }
	
}
triggerdeliveryclick=(adid) => {
	localStorage.setItem("ISIbiladrsid",adid)
	
	this.setState({redirectdate: true})
		 this.setState({ stendisabled:true,btnsubmit:"Processing..."  })  			

}
triggershipdeliveryclick=(adid) => {
	localStorage.setItem("ISIshipadrsid",adid)
	
	this.setState({redirectdate: true})
		 this.setState({ stendisabled:true,btnsubmit:"Processing..."  })  			

}
renderRedirect = () => {
  if (this.state.redirect) {
      return <Navigate to={this.state.pagename} />
  }
  }	
render(){
	
	if (this.state.redirectdate) {
      return <Navigate to='/UserAddresslist' />
  }
 if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
	}
return (




					
  <React.Fragment>
  {this.renderRedirect()}
  
  
  {this.state.strtype===1 && (
<ul className="myaddrs">
 <FormControl component="fieldset"> 
 <FormLabel id="demo-controlled-radio-buttons-group">Billing Address</FormLabel>
  <RadioGroup value={this.state.radiodefaultValue} aria-label="gender" name="customized-radios">
{this.state.data.map((item,idx) => {
	
 return ( <React.Fragment>	
<FormControlLabel value={item.ad_id} onClick={()=>{this.selectaddress(item.ad_id)}} control={<Radio />} label={item.ad_billingname+" - "+ item.ad_billingaddress}  /> 
<div className="primarybtn"
 onClick={()=>{this.triggerdeliveryclick(item.ad_id)}}
 disabled={this.state.stendisabled}>
 {this.state.btnsubmit} 
 </div>
 &nbsp;
<div className="secondarybtn" onClick={()=>{this.triggerdelete(item.ad_id)}} > Delete
<DeleteIcon  className="cusrsoor iconvertical"/>
</div>
<div className="clear"></div>
 </React.Fragment>
)
})}

<div className="clear"></div>
<FormControlLabel value="0" onClick={()=>{this.triggerNewaddres(0)}} control={<Radio />} label=" Add Billing Address" />

 </RadioGroup>
    </FormControl>
 
</ul>
  )}
  {this.state.strtype===2 && (
<ul className="myaddrs">

<FormControl component="fieldset"> 
 <FormLabel id="demo-controlled-radio-buttons-group">Shipping Address</FormLabel>
  <RadioGroup value={this.state.shipValue} aria-label="gender" name="customized-radios">
{this.state.shipdata.map((item,idx) => {
	
 return ( <React.Fragment>	
<FormControlLabel value={item.ad_id} onClick={()=>{this.selectaddress(item.ad_id)}} control={<Radio />} label={item.ad_billingname+" - "+ item.ad_billingaddress}  /> 
<div className="primarybtn"
 onClick={()=>{this.triggershipdeliveryclick(item.ad_id)}}
 disabled={this.state.stendisabled}>
 {this.state.btnsubmit} 
 </div>
  &nbsp;
<div className="secondarybtn" onClick={()=>{this.triggerdelete(item.ad_id)}}> Delete
<DeleteIcon   className="cusrsoor iconvertical"/>
</div>
<div className="clear"></div>
 </React.Fragment>
)
})}

<div className="clear"></div>
<FormControlLabel value="0" onClick={()=>{this.Changeshipaddress(0)}} control={<Radio />} label=" Add New Address" />
</RadioGroup>
    </FormControl>
</ul>  )}
</React.Fragment>

)
	
	
	
}
	 
}


