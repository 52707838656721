import React, { Component } from "react";



const Serverurl= ["http://golegappa.com/appfiles/"]
//const Serverurl= ["http://localhost/xampp/apoorva/isifol/"]


export default Serverurl;






