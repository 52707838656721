import React ,{Component} from 'react';
import { makeStyles } from '@mui/styles';  
import { Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import PropTypes from 'prop-types';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import save from './images/Save.jpg';
import delet from './images/delete.jpg';
import view from './images/view.jpg';
import TextField from '@mui/material/TextField';
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Salesdashboard extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[],StorelatestData:[],StorerecentData:[],StoreadminData:[],qty:"1"       }
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	document.title = "Dashboard";
	
	
 
  this.apicall(); 

}



apicall(){
	
	 axios.get(Serverurl+"ajaxdashboard.php?action=list&userid=0")
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }

    });
	
	
}

 

  
render() {
	
if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }
  
  	 
    
  return (
  
    <React.Fragment>
   
     
  

    <div className="dashboard">

 
<h2>Welcome to Baawarchi</h2>
		        

                     {stableSort(this.state.StoreData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>


<ul>
<li>

<div className="count"><h3><a href="#/category">{item.catcount}</a></h3><p><a href="#/category">CATEGORIES</a></p></div>
<div className="clear"></div>

</li>
<li>

 
<div className="count"><h3><a href="#/Adminproduct">{item.prodcount}</a></h3><p><a href="#/Adminproduct">PRODUCTS</a></p></div>
<div className="clear"></div>

</li>
{item.ordercount ==='0' &&(<React.Fragment><li>

<div className="count"><h3><a href="#/adminorders">{item.ordercount}</a></h3><p><a href="#/adminorders">ORDERS</a></p></div>
<div className="clear"></div>
</li></React.Fragment>)}
 {item.ordercount !=='0' &&(<React.Fragment> <li>

<div className="count"><h3><a href="#/adminorders">{item.ordercount}</a></h3><p><a href="#/adminorders">ORDERS</a></p></div>
<div className="clear"></div>
</li> </React.Fragment>)}

<li>

 
<div className="count"><h3><a href="#/Cutsomerdisplay">{item.customercount}</a></h3><p><a href="#/Cutsomerdisplay">CUSTOMERS</a></p></div>
<div className="clear"></div>

</li>
</ul>




				</React.Fragment>	
				);
					})}
                     

                   
   
 </div>

    
   
 




   
 </React.Fragment>
		
		  
	 


  );
  }	
   
   


}