import React ,{Component} from 'react'; 
import { Navigate  } from 'react-router-dom';
import axios from 'axios'; 
import Table from '@mui/material/Table';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'; 
import { makeStyles } from '@mui/styles'; 
import Paper from '@mui/material/Paper';  
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody'; 
import TableCell from '@mui/material/TableCell';  
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';  
import TablePagination from '@mui/material/TablePagination'; 
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
const headCells = [


 
 
  
 { id:'cs_name', numeric: false, label: 'Name' },
   { id:'cs_email', numeric: false, label:'Email Id' },
  
   { id:'cs_phonenumber', numeric: false, label:'Phone Number' },
  { id: 'cs_role', numeric: false, label: 'Role' },
  { id: 'cs_username', numeric: false, label:'User Name' },
   { id: '', numeric: false, label:'' },
 
 ]
 function stableSort(array, cmp) {
	  const stabilizedThis = array.map((el, index) => [el, index]);
	  stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	  return stabilizedThis.map(el => el[0]);
}
function desc(a, b, orderBy) {
	  if (b[orderBy] < a[orderBy]) {
		return -1;
	  }
	  if (b[orderBy] > a[orderBy]) {
		return 1;
	  }
	  return 0;
}
function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
 
function EnhancedTableHead(props) {
  const { classes,  order, orderBy,  rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={useStyles.visuallyHidden}>
                  {order === 'desc' ? ' ' : ' '}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
 
  onRequestSort: PropTypes.func.isRequired,
 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  
  
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  };

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 
 icon: {
   verticalalign:'middle',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }, 


});


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default class CutsomerPage extends Component {
	
    constructor(props) {
    super(props);
   
     this.state = {redirect: false,csid:'',btnsavetxt:'Save',btnsavedisbaled:false,
     customerdata:[],page: 0 ,prevpage:0,totaldata:0,rowsPerPage:20,rowsPaging:[],name:'',
	 
	  order:'desc' ,orderBy:'cs_id',cs_id:0,cs_name:'',cs_email:'',cs_phonenumber:'',cs_role:'',cs_username:'',
	          
      }
       this.updateDonenewParent = this.updateDonenewParent;
	   
	}
	
	updateDonenewParent = (text) => {this.componentDidMount();  }
	
	Search(){
	this.setState({  
      page: 0,
      rowsPerPage:20,
	  prevpage:0,Customerdata:[]
    });
	
 
}






	componentDidMount() {
if(localStorage.getItem("ISIuserid")===null)
	{
		
	
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}else if(	localStorage.getItem("ISIrole")!=="1")
		
		{
			this.setState({
            redirect: true,
			pagename:'Login'
          })
			
		}document.title = "Baawarchi - Customer";
	  localStorage.setItem("page",this.state.rowsPerPage)
  this.apicall(this.state.page,this.state.rowsPerPage);
 
} 

		

  


handleClickSearch=()=>{   
 
  
  this.apicall(this.state.page,this.state.rowsPerPage); 

	
} 

myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});
 
}

apicall(page,rowsPerPage){
	
	

 
			 axios.get(Serverurl+'ajaxcustomer.php?action=list&cs_role='+this.state.cs_role+'&cs_name='+this.state.name+'&StartIndex='+page+'&PageSize='+rowsPerPage)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  customerdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
		
	 
     }
	 else{
		   this.setState({customerdata:[],totaldata:0});
	 }
 
    });
}



handleChangePage = (event, newPage) => {  
   
    let pageval = localStorage.getItem("page");
    this.setState({  
      page: newPage,
      rowsPerPage:parseInt(pageval)
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage); });
   
 

  };  
  
  
   handleChangeRowsPerPage = event => {  
  
    let pageval = event.target.value;
    localStorage.setItem("page",pageval)

   
    this.setState({  
      page: 0,
      rowsPerPage:parseInt(pageval),
	  prevpage:-1
    },()=>{  this.apicall(this.state.page,this.state.rowsPerPage); }); 
	
	
  }; 
  
  
  handleRequestSort = (event, property) => {
  const isAsc = this.state.orderBy === property && this.state.order === 'asc';
  

  this.setState({   orderBy: property,
    order:isAsc  ? 'desc' : 'asc'
   
  });
};




   CustomerDelete(csid){  

let r = window.confirm("Do you really want to Delete?");
 if(r === true){ //sign Out logic
 var formData = new FormData();

     formData.append('action','delete')
	 
	 formData.append('cs_id',csid)
	 
		   
 formData.append('userid',localStorage.getItem("ISIuserid"))
	
	 axios.post(Serverurl+"ajaxcustomer.php", formData, {
        }).then(res => {
    
              this.apicall(this.state.page,this.state.rowsPerPage); 
 

  
    
            });
 }
      }
	  
	  
 
    CategoryaddBox(val){
        this.setState({csid:val },()=>{this.refs.AddCustomerBox.handleClickOpen()});  

        

      }


render() {

if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }


  return (
   <React.Fragment>

   <div className="searchpart">
			<ul>
				
						
<li><p>Name/ Email Id/ Phone</p>
			<p ><TextField
					
					
            halfWidth value={this.state.name}
			inputProps={{tabIndex: "1",maxLength:150}}
			
           name="name"  onChange={this.myChangeHandler}
			
          /></p>
	</li>
		
	<li><p>Role</p>
				<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.cs_role}
		onChange={this.myChangeHandler}
		name='cs_role'
		inputProps={{tabIndex: "4"}}
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Role</option>
		<option value={1}>Admin</option>
		<option value={2}>Sales</option>		
		<option value={3}>Customer</option>		
		</Select>
		</FormControl></p>
	</li>		
			
			
		
		<li>
		<p>&nbsp;</p>
		<p><Button className="primarybtn"  variant="contained" onClick={this.handleClickSearch}  >
			Search
			</Button></p></li>	
			
			
			<li className="floatright"><p>&nbsp;</p><p>
			<a href="#/AdminCustomer">
  <Button  variant="contained"   className="generalbtn" >
				New 	
		</Button>
		</a>
			</p>
			</li>
		</ul>

   
   </div>
 <AddCustomerBox ref="AddCustomerBox" csid={this.state.csid}    updateDonenew={this.updateDonenewParent}/>


  <TableContainer className={useStyles.container}>  
  
 
                <Table stickyHeader aria-label="sticky table">  
<TableHead> 
<TableRow>
                                              
                       
						
                      </TableRow></TableHead>
					  
					   <EnhancedTableHead           
              order={this.state.order}
              orderBy={this.state.orderBy}
              
              onRequestSort={this.handleRequestSort}
              rowCount={this.state.customerdata.length}
            />
                      <TableBody>
                     {stableSort(this.state.customerdata, 
			getSorting(this.state.order,
			this.state.orderBy)).map((item,index) => {  
			
           return (
 <React.Fragment>
 <TableRow key={index} >



<TableCell>{item.cs_name}</TableCell>
<TableCell>{item.cs_email}</TableCell>
<TableCell>{item.cs_phonenumber}</TableCell>
<TableCell>{item.roles}</TableCell>
<TableCell>{item.cs_username}</TableCell>

<TableCell align="left">


	<a href={'#/AdminCustomer?csid='+item.cs_id}>
<Button variant="contained"  className="secondarybtn"   startIcon={<EditIcon />}>
Edit
</Button></a> &nbsp;<Button variant="contained"  className="secondarybtn"  onClick={()=>{this.CategoryaddBox(item.cs_id)}} >
				Address
		</Button> &nbsp; {item.cs_role!=="1" && (<Button variant="contained"   className="generalbtn"  onClick={()=>{this.CustomerDelete(item.cs_id)}}  startIcon={<DeleteIcon />}>
  Delete
		</Button> )}





	

 
 </TableCell>
 </TableRow>

 </React.Fragment>	
);
					})}
					
					{this.state.totaldata===0 &&(
					
					<TableRow key={0} >
<TableCell align="center" colspan="4">No Records</TableCell>
					</TableRow>
					) }
					
                      </TableBody>


</Table>
  </TableContainer> 
  <TablePagination  
        rowsPerPageOptions={[5,10, 20, 50,100]} 
        component="div"  
        count={this.state.totaldata}  
        rowsPerPage={this.state.rowsPerPage}  
        page={this.state.page}  
        onPageChange={this.handleChangePage}  
        onRowsPerPageChange={this.handleChangeRowsPerPage}  
      />
 </React.Fragment> 

 
  );

}

}



export  class  AddCustomerBox extends Component {

  constructor(props) {
    super();

    this.state = {adid:'',AddressData:[],csid:'',open:false,ad_name:"",ad_id:'',isdefaultaddress:false,btndisable:false,btntext:'Save',pagename:'',erroropen:false,errormessage:'',
	strreturn:'', redirect: false,ad_billingaddress:'',ad_billingcity:'',ad_billingstate:'',ad_billingcountry:'',ad_billingpostalcode:'',ad_cs_id:'',ad_isdefault:'',};
   
}
   myChangeHandler = (event) => {
	   
	 
	   
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val});
	
	
	
  
}
 
	
	  handleClickOpen = (e) => {

  this.setState({open:!this.state.open,
     errormessage:'',erropen:false,
	 ad_name:"", 
	ad_billingaddress:"", 
	
	ad_billingstate:"",
	ad_billingcity:"",
	ad_billingstate:"",
	isdefaultaddress:false,
	ad_billingpostalcode:"",
	ad_billingcountry:"",

	ad_id:"",
	  });
	
	 this.addressapicall(this.props.csid);
	
	
};

 handleErrorclose=(event)=>{
	  
	this.setState({erropen: false});  
  }
  
  






  	
handleSubmit= (event) => {
    
    event.preventDefault();
	
   
    let ad_billingaddress = this.state.ad_billingaddress;
    let ad_billingcity = this.state.ad_billingcity;
	let ad_billingstate = this.state.ad_billingstate;
	
    let ad_billingcountry = this.state.ad_billingcountry;
	let ad_billingpostalcode = this.state.ad_billingpostalcode;
	let ad_isdefault = this.state.ad_isdefault;
	let isdefaultaddress = this.state.isdefaultaddress;

	 let ad_name = this.state.ad_name;
	
	
		if(ad_name==="")
{
	this.setState({errormessage:"Please Enter Address Name.",erroropen:true});
	window.scrollTo({
          top: 0
      })
		 return false;
	
}
	if(ad_billingaddress==="")
{
	this.setState({errormessage:"Please Enter Address.",erroropen:true});
	window.scrollTo({
          top: 0
      })
		 return false;
	
}
if(ad_billingcity==="")
{
	this.setState({errormessage:"Please Enter City.",erroropen:true});
	window.scrollTo({
          top: 0
      })
		 return false;
	
}
	if(ad_billingstate==="")
{
	this.setState({errormessage:"Please Enter State.",erroropen:true});
	window.scrollTo({
          top: 0
      })
		 return false;
	
}
if(ad_billingpostalcode==="")
{
	this.setState({errormessage:"Please Enter Postalcode.",erroropen:true});
	window.scrollTo({
          top: 0
      })
		 return false;
	
}
if(ad_billingcountry==="")
{
	this.setState({errormessage:"Please Enter Country.",erroropen:true});
	window.scrollTo({
          top: 0
      })
		 return false;
	
}






	
	 var formData = new FormData();
	 
	 
	 
	if(this.state.ad_id==0)
	  {
		  formData.append('ad_id',0) 
		  
	  }
	  else
	  {
		  
	   formData.append('ad_id',this.state.ad_id)
		
          }
	 

	
	  
 this.setState({btndisable:true,btntext:'Processing...',});
       formData.append('action','insertorupdate')
	  	     formData.append('ad_billingname',ad_name)
	   formData.append('ad_billingaddress',ad_billingaddress)
	    formData.append('ad_billingcity',ad_billingcity)
		
	 formData.append('ad_billingstate',ad_billingstate)	
		
		formData.append('ad_billingcountry',ad_billingcountry)
	  
	   formData.append('ad_billingpostalcode',ad_billingpostalcode)
	   
	   if(isdefaultaddress===false)
	   {
	   formData.append('ad_isdefault',1)
	   }
	   else
	   {
		 formData.append('ad_isdefault',2)  
	   }
	    
		
	

	   
	formData.append('userid',localStorage.getItem("ISIuserid"))
	formData.append('ad_cs_id',this.props.csid)
	   
	         
	   	  axios.post(Serverurl+"ajaxaddress.php", formData, {
        }).then(res => {
			 this.setState({
				 btndisable:false,btntext:'Save' ,open:false,
            
          })
		  this.addressapicall(this.props.csid);
		  
            });
	
        
  };
  
    addressapicall(csid){
	
	 axios.get(Serverurl+"ajaxaddress.php?action=list&ad_cs_id="+csid)
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  AddressData: resp.data, 
		  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	
     }
  
    }); 
	

}



  onclickadrsdlete(id){
	 let r = window.confirm("Do you really want to Delete?");
		 if(r == true){ //sign Out logic 
	  var formData = new FormData();
	 formData.append('action','delete') 
	  formData.append('ad_id',id)
	  
	     formData.append('userid',localStorage.getItem("ISIuserid"))
 axios.post(Serverurl+"ajaxaddress.php", formData, {
        }).then(res => {
   
            	 this.addressapicall(this.props.csid);
	  
    
            });
		 }			
  }
  
 
  
  
  
  AddressEdit(Adid){ 


	localStorage.setItem("ISIuserid",Adid);

							
this.setState({redirect:true,pagename:'/'});
				
       
}
  
  
	  
handleChckbxChange = (event) => {
   
	this.setState({isdefaultaddress: !this.state.isdefaultaddress});

  }; 	


  
	
	
	
	onloadaddressbyid(adid){

		 axios.get(Serverurl+'ajaxaddress.php?action=list&ad_id='+adid)
  .then(resp => {  
   if(resp.data.length>0){
	   
    this.setState({ 
   	
 	ad_name:resp.data[0].ad_billingname, 
	ad_billingaddress:resp.data[0].ad_billingaddress, 
	
	ad_billingstate:resp.data[0].ad_billingstate,
	ad_billingcity:resp.data[0].ad_billingcity,
	ad_billingstate:resp.data[0].ad_billingstate,
	isdefaultaddress:resp.data[0].ad_isdefault,
	ad_billingpostalcode:resp.data[0].ad_billingpostalcode,
	ad_billingcountry:resp.data[0].ad_billingcountry,

	ad_id:resp.data[0].ad_id,
	

	});
	
   }
  });
	
	
	}

render (){

  return (
  
  
	 <React.Fragment>  
	 
	 <div className="customer-dialogue">

    <Dialog open={this.state.open} onClose={this.SubmitForm} maxWidth="lg" fullWidth="true"  aria-labelledby="form-dialog-title">

	   
	      <DialogTitle id="form-dialog-title"> <h4>Cutsomer</h4></DialogTitle>
      <DialogContent>
<p>  <Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>


<form  className="address-form" onSubmit={this.handleSubmit} >



<div className="adminform">


	


	
	<ul>
	<li>
		<p><span className="validation">*</span>Address Name</p>
		<p><TextField 
            variant="outlined"
            margin="normal"
           value={this.state.ad_name}
			onChange={this.myChangeHandler}
            fullWidth
			type="text"
            id="ad_name"
          	inputProps={{tabIndex: "1",maxLength:150}}
            name="ad_name" 
            
			
          /></p>
		  
	</li>
		
			
		
	
		 	  
	<li>
		<p><span className="validation">*</span>City</p>
		<p><TextField 
            variant="outlined"
            margin="normal"
           value={this.state.ad_billingcity}
			onChange={this.myChangeHandler}
            fullWidth
			type="text"
            id="ad_billingcity"
          	inputProps={{tabIndex: "3",maxLength:150}}
            name="ad_billingcity" 
            
			
          /></p>
		  
	</li>
		
		
	<li>	
			<p><span className="validation">*</span>State</p>
			<p><TextField 
            variant="outlined"
            margin="normal"
           value={this.state.ad_billingstate}
			onChange={this.myChangeHandler}
            fullWidth
				inputProps={{tabIndex: "4",maxLength:150}}
            id="ad_billingstate"
            type="ad_billingstate"
            name="ad_billingstate" 
            
			
          /></p>
	</li>
		  
		  	  
		 
	
		

		 

		
		
	<li>	
		<p><span className="validation">*</span>Postal Code</p>
		<p><TextField 
            variant="outlined"
            margin="normal"
           value={this.state.ad_billingpostalcode}
			onChange={this.myChangeHandler}
            fullWidth
				inputProps={{tabIndex: "5",maxLength:150}}
            id="ad_billingpostalcode"
            type="ad_billingpostalcode"
            name="ad_billingpostalcode" 
            
			
          /></p>
	</li>
	
	
	
			<li>
		<p><span className="validation">*</span>Country</p>
			<p><TextField 
				variant="outlined"
				margin="normal"
			   value={this.state.ad_billingcountry}
				onChange={this.myChangeHandler}
				fullWidth
				inputProps={{tabIndex: "6",maxLength:150}}
				id="ad_billingcountry"
				type="ad_billingcountry"
				name="ad_billingcountry" 
				
			
          /></p>
		</li>
		 <li>
		<p><span className="validation">*</span>Address</p>
		<p><TextField 
            variant="outlined"
            margin="normal"
           value={this.state.ad_billingaddress}
			onChange={this.myChangeHandler}
            fullWidth
			type="Textarea"
            id="ad_billingaddress"
          	inputProps={{tabIndex: "3",}}
            name="ad_billingaddress" 
            multiline rows="3"
			
          /></p>
		  
	</li>
		 </ul>
		 
		 
		 
	</div>	 
		 
		 
		 
	
		
		
	
	
		
		<div className="address-btn">
		
		
		
		  <FormControlLabel
            control={<Checkbox value="remember"
			inputProps={{ tabIndex: "7" }}  
			checked={this.state.isdefaultaddress}  
			onChange={this.handleChckbxChange} />}
           label="Make this as Shipping Address"
			
          />
		
          
	
		 

		
		
</div>

<div className="clear"></div>


       <div className="dialoguebtn">
		<ul>	  
			<li><Button  className="generalbtn" onClick={this.handleClickOpen} variant="contained">
					Cancel
				</Button></li>
		
		
		<li><Button className="primarybtn" type="submit"
          
            variant="contained" 
			id="userbutton" 
			disabled={this.state.btndisable}
           
			onClick={this.handleSubmit}>
			Save
		</Button></li>

	
		</ul>		
	</div>
			 
         
  </form>


<div className="clear"></div>


<div className="address-div">

{this.state.AddressData.map((item,index) => {
		   return ( <React.Fragment>
<p> {item.ad_billingaddress},<br/> {item.ad_billingcity},<br/>{item.ad_billingstate}-{item.ad_billingpostalcode},<br/>{item.ad_billingcountry}</p>
	



<p><Button onClick={()=>{this.onloadaddressbyid(item.ad_id)}} variant="contained"  className="secondarybtn"
> Edit </Button> &nbsp;<Button id='deleteaddrs' variant="contained"   className="generalbtn"  onClick={()=>{this.onclickadrsdlete(item.ad_id)}}>Delete</Button></p>
</React.Fragment>	
				);
					})}

</div>






 </DialogContent>
      
    </Dialog></div>
	</React.Fragment>
	



  ); }
 
} 
  