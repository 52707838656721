import React ,{Component} from 'react';
import ReactDOM from "react-dom";
import { HashRouter as Router, Route ,Routes } from 'react-router-dom';
import './App.css';
import { Navigate  } from 'react-router-dom';
import './components/styles/styles.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Layouthead from "./components/Layouthead";
import LayoutProdcontent from "./components/LayoutProdcontent";
import Layoutcontent from "./components/Layoutcontent";
import Userdashboard from "./components/Userdashboard";
import Salesdashboard from "./components/Salesdashboard";
import Admindashboard from "./components/Admindashboard";
import Smartbasket from "./components/Smartbasket";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Changepassword from "./components/Changepassword";
import Myaccount from "./components/Myaccount";
import Product from "./components/Product";
import MyCart from "./components/Cart";
import UserAddresslist from "./components/UserAddresslist";
import MyAddressEntry from "./components/AddressForm";
import Order from "./components/Order";
import OrderConfirm from "./components/OrderConfirm";
import Myorder from "./components/Myorder";
import Category from "./components/Category";
import Vieworder from "./components/Vieworder";
import AdminCustomer from "./components/Admincustomer";
import Cutsomerdisplay from "./components/Cutsomerdisplay";
import Adminproduct from "./components/Adminproduct";

import Orderdetails from "./components/orderdetails";
import Orderhistory from "./components/Orderhistory";
import Myaddresslis from "./components/Myaddresslis";
import Productactions from "./components/Productactions";
import Adminorderviewmore from "./components/Adminorderviewmore";
import Adminorders from "./components/Adminorders";
import Addproduct from "./components/Addproduct";

import Productview from "./components/Productview";
class App extends Component {
	 constructor(props) {
    super(props);
   
     this.state = {name:'',qty:0,redirect:false,pagename:''}
     }
    handleCallback = (childData) => {
        // Update the name in the component's state
		
        this.setState({ qty: childData })
	
    }
	   handleloadTitle = (childData) => {
        // Update the name in the component's state
		var str=childData.split('~');
        this.setState({ name: str[0],qty: str[1] })
	
    }
	componentDidMount() {
		window.addEventListener('storage', e => {


   if(!e.storageArea.token) {
	   window.location.href = window.location.origin + "/";
    }

});
	}
	/*doSomething= (arg1, component) => {
		
		this.setState({ qty: arg1 })
		 console.log(arg1);
  return component;
	}*/
render() {
	if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
    return ( 
	
	<Router basename="/">

	
	
	
	  <Routes>
	   
	 <Route element={<Layouthead qty={this.state.qty}/>}>
	 	   <Route path="/Category" element="Category" /> 
		    <Route path="/AdminCustomer" element="Customer Management" /> 
			<Route path="/Cutsomerdisplay" element="Customer Management" /> 
			<Route path="/Addproduct" element="Product Management" /> 
			<Route path="/Adminorders" element="Order Management" /> 
			<Route path="/Adminorderviewmore" element="Order Management" /> 
			
	 <Route path="/Productview" element={"Products"} /> 
	 <Route path="/Myaddresslis" element="Address" />
	 <Route path="/Orderhistory" element="Order History" /> 
	 <Route path="/Adminproduct" element="Product Management" /> 
	   <Route path="/Userdashboard" element="Dashboard" /> 
<Route path="/Salesdashboard" element="Dashboard"/>
<Route path="/Admindashboard" element="Dashboard" /> 
 <Route path="/Smartbasket" element="Smart Basket" /> 	
<Route path="/Changepassword" element="Change Password" /> 
<Route path="/Myaccount" element="My Account" /> 
  <Route path="/Product" element="Products" /> 
    <Route path="/MyCart" element={<div><a href="#/Product"><ArrowBackIcon /></a> My Cart</div>} /> 
	 <Route path="/UserAddresslist" element={<div><a href="#/MyCart"><ArrowBackIcon /></a> Checkout</div>} /> 
	 
   <Route path="/MyAddressEntry" element={<div><a href="#/UserAddresslist"><ArrowBackIcon /></a> Address</div>} />
	    
<Route path="/Order" element={<div><a href="#/UserAddresslist"><ArrowBackIcon /></a> Checkout</div>} />
	<Route path="/Orderdetails" element={<div><a href="#/Myorder"><ArrowBackIcon /></a> My Orders</div>}  /> 
	<Route path="/Vieworder" element={<div><a href="#/Orderhistory"><ArrowBackIcon /></a>Order History</div>} />
	  <Route path="/Myorder" element="My Orders" /> 
	<Route path="/OrderConfirm" element={<div><a href="#/UserAddresslist"><ArrowBackIcon /></a> Order Confirmation</div>} />
		</Route>
	<Route exact path="/"  element={<Navigate to="/Login" />}  />
            
            <Route path="/Login" element={<Login/>}/>
            <Route path="/Logout" element={<Logout/>}/>   
	  </Routes>
	   <Routes>
	   
	 <Route element={<Layoutcontent />}>
	 
	 <Route path="/Orderdetails" element={<Orderdetails />} /> 
	 <Route path="/Adminorders" element={<Adminorders />}  /> 
	 <Route path="/Myaddresslis" element={<Myaddresslis />} /> 
	 <Route path="/Adminorderviewmore" element={<Adminorderviewmore />}  /> 
	 	 <Route path="/Addproduct" element={<Addproduct />}  /> 
	 <Route path="/Category" element={<Category />} /> 
	 <Route path="/Adminproduct" element={<Adminproduct />}  /> 
	 <Route path="/Cutsomerdisplay" element={<Cutsomerdisplay />} /> 
	     <Route path="/AdminCustomer" element={<AdminCustomer />} />
	   <Route path="/Userdashboard" element={ <Userdashboard parentdasCallback={this.handleCallback}/> } /> 
<Route path="/Salesdashboard" element={<Salesdashboard />} /> 
<Route path="/Admindashboard" element={<Admindashboard />} />
<Route path="/Smartbasket" element={<Smartbasket  parentbasCallback={this.handleCallback}/>} />  

<Route path="/Productview" element={<Productview  parentbasCallback={this.handleloadTitle}/>} /> 
<Route path="/Changepassword" element={<Changepassword />} />
<Route path="/Myaccount" element={<Myaccount />} /> 
	 <Route path="/Orderhistory" element={<Orderhistory />} />
 	       <Route path="/MyCart" element={<MyCart parentcartCallback={this.handleCallback}/>} /> 
		   	 <Route path="/UserAddresslist" element={<UserAddresslist />} /> 
			 <Route path="/MyAddressEntry" element={<MyAddressEntry />}/>
			  <Route path="/OrderConfirm" element={<OrderConfirm />} />
			 <Route path="/Order" element={<Order />} />
			  <Route path="/Myorder" element={<Myorder />} /> 
			  <Route path="/Vieworder" element={<Vieworder />} /> 
	     </Route>
	   
	  </Routes>
	
	    <Routes> <Route element={<LayoutProdcontent />}>
		<Route path="/Product" element={<Product parentCallback={this.handleCallback}/>} />  
		</Route>
  </Routes>
	
	  </Router>
	
	
    );
  }
}


export default App;
