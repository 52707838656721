import React, { Component } from "react";
import Serverurl from './Apiurl';
import axios from 'axios'; 

import { makeStyles } from '@mui/styles'; 
import Button from '@mui/material/Button';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate  } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
export default class  Product extends Component {
	
	 constructor(props) {
      super(props)
      this.state = {data:[],shipdata:[],redirect:false,pagename:'',stendisabled:false,btnsubmit:"Submit",radiodefaultValue:0,shipValue:0,redirectdate:false,
	  Orname:'',Oremail:'',Orphone:'',subName:'',subEmail:'',subPhone:'',Orinstruction:'',OrAddress:"",Orcity:"",Orstate:"",erroropen:false,alerttype:"error",errormessage:"",
	OrshipAddress:"",Orshipcity:"",Orshipstate:"",Orshippin:"",Orshipcountry:"",
	  }
	
     }
	 componentDidMount() {
		 document.title = "Baawarchi Checkout";
		 if(localStorage.getItem("ISIuserid")!==null)
	{
		
	}else{
		this.setState({
            redirect: true,
			pagename:'Login'
          })
	}this.ApiGetCustomer();
			
		 
	 }

	ApiGetCustomer(){
	axios.post(Serverurl+'ajaxcustomer.php?action=list&cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			 Orname:resp.data[0].cs_name,
			  Oremail:resp.data[0].cs_email,
			    Orphone:resp.data[0].cs_phonenumber,
			});
			
		 this.BillingAddressapicall(); 
     }
  
    });
	
} 
	 
 BillingAddressapicall(){
	 let strUrl="";
	if(localStorage.getItem("ISIbiladrsid")!=null)
	{
		strUrl="&ad_id="+localStorage.getItem("ISIbiladrsid");
	}else{
		strUrl="&ad_isdefault=1";
	}
  axios.post(Serverurl+'ajaxaddress.php?action=list'+strUrl+'&ad_type=1,3&ad_cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			  data: resp.data,  
			  radiodefaultValue: resp.data[0].ad_id,
			   OrAddress:resp.data[0].ad_billingaddress,Orcity:resp.data[0].ad_billingcity,Orstate:resp.data[0].ad_billingstate,
	Orpin:resp.data[0].ad_billingpostalcode,Orcountry:resp.data[0].ad_billingcountry
			  
			});
			
		
     }
  this.ShippingAddressapicall();
    });
}
 ShippingAddressapicall(){
	 let strUrl="";
	if(localStorage.getItem("ISIshipadrsid")!=null)
	{
		strUrl="&ad_id="+localStorage.getItem("ISIshipadrsid");
	}else{
		strUrl="&ad_isdefault=1";
	}
  axios.post(Serverurl+'ajaxaddress.php?action=list'+strUrl+'&ad_type=2,3&ad_cs_id='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
			this.setState({  
			  shipdata: resp.data,  
			  shipValue: resp.data[0].ad_id,
			  
			   OrshipAddress:resp.data[0].ad_billingaddress,Orshipcity:resp.data[0].ad_billingcity,Orshipstate:resp.data[0].ad_billingstate,
	Orshippin:resp.data[0].ad_billingpostalcode,Orshipcountry:resp.data[0].ad_billingcountry
			
			});
			
		
     }
  
    });
}

Changeaddress(){
	
	this.setState({redirect:true,pagename:'/Myaddresslis?type=1'});
	
}
Changeshipaddress(){
	
	this.setState({redirect:true,pagename:'/Myaddresslis?type=2'});
	
}
selectaddress= (daid) => {
    

	this.setState({radiodefaultValue: daid})
}
selectshipaddress= (daid) => {
    

	this.setState({shipValue: daid})
}
triggerNewaddres= (val) => event  => {
    
 
	this.setState({redirect: true,pagename:'/MyAddressEntry?type='+val})
}

OnclickOrderSubmit= (event) => {
	var self = this;
	   
    event.preventDefault();
		const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	 let Orname = this.state.Orname;
    let Oremail = this.state.Oremail;
	 let Orphone = this.state.Orphone;
	  let subName = this.state.subName;
	let subEmail = this.state.subEmail; 
	let subPhone = this.state.subPhone; 
	
	let  OrAddress=this.state.OrAddress,
			 Orcity=this.state.Orcity,
			 Orstate=this.state.Orstate,
	Orpin=this.state.Orpin,
	Orcountry=this.state.Orcountry,
	 OrshipAddress=this.state.OrshipAddress,
			 Orshipcity=this.state.Orshipcity,
			 Orshipstate=this.state.Orshipstate,
	Orshippin=this.state.Orshippin,
	Orshipcountry=this.state.Orshipcountry,
	Ormessage=this.state.Ormessage;
	if(Orname==="")
  {	  
    this.setState({ errormessage:'Please Enter Name',erroropen:true});
  
   window.scrollTo({
				  top: 0
			  })
			  return false;
  }
  if(Oremail==="")
  {	  
    this.setState({ errormessage:'Please Enter Email id',erroropen:true});

   window.scrollTo({
				  top: 0
			  })
			  return false;
  }		 else  
  {
 if (!remail.test(Oremail)) {
	 this.setState({errormessage:"Please Enter Valid Email Id",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
  }
	  
  }
   if(Orphone==="")
  {	  
    this.setState({ errormessage:'Please Enter Phone No.',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  }) 
			  return false;
  }
   
    if(this.state.data.length===0==="")
  {	  
    this.setState({ errormessage:'Please Enter Address',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  }) 
			  return false;
  }
  if(Orcity==="")
  {	  
   this.setState({ errormessage:'Please Enter City',erroropen:true});
  
   window.scrollTo({
				  top: 0
			  })
			  return false;
 
  }
   if(Orstate==="")
  {	  
    this.setState({ errormessage:'Please Enter State',erroropen:true});
  
   window.scrollTo({
				  top: 0
			  })
			  
			  return false;
  }
   if(Orcountry==="")
  {	  
  this.setState({ errormessage:'Please Enter Country',erroropen:true});
  
   window.scrollTo({
				  top: 0
			  })
			  return false;

  }
   if(Orpin==="")
  {	  
  this.setState({ errormessage:'Please Enter Postal Code',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  }) 
			  return false;

  } if(this.state.shipdata.length===0)
  {	  
    this.setState({ errormessage:'Please Add Shipping Address',erroropen:true});
  
   window.scrollTo({
				  top: 0
			  })
			  return false;
  }
  if(Orshipcity==="")
  {	  
   this.setState({ errormessage:'Please Enter City',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  })
			  return false;
 
  }
   if(Orshipstate==="")
  {	  
    this.setState({ errormessage:'Please Enter State',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  })
			  return false;
  }
   if(Orshipcountry==="")
  {	  
  this.setState({ errormessage:'Please Enter Country',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  }) 
			  return false;

  }
   if(Orshippin==="")
  {	  
  this.setState({ errormessage:'Please Enter Postal Code',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  })
			  return false;

  }var formData = new FormData();
	 formData.append('action','insertorupdate') 
	  formData.append('or_cs_id',localStorage.getItem("ISCustid")) 
	   formData.append('userid',localStorage.getItem("ISIuserid"))
formData.append('or_name',Orname)
formData.append('or_emailid',Oremail)
formData.append('or_phoneno',Orphone)
formData.append('or_submittedname',subName)
formData.append('or_submittedemailid',subEmail)	
formData.append('or_submittedphoneno',subPhone)
formData.append('or_billingaddress',OrAddress)	
formData.append('or_billingcity',Orcity)
formData.append('or_shippingpincode',Orpin)	
formData.append('or_bilingstate',Orstate)
formData.append('or_country',Orcountry)	
formData.append('or_shippingaddress',OrshipAddress)	
formData.append('or_shippingcity',Orshipcity)
formData.append('or_shippingpincode',Orshippin)	
formData.append('or_shippingstate',Orshipstate)
formData.append('or_shipcountry',Orshipcountry)	
formData.append('or_sessionid',localStorage.getItem("ISIsession"))
formData.append('or_instruction',Ormessage)	
axios.post(Serverurl+"ajaxorder.php", formData, {
        }).then(res => {
			
localStorage.setItem("ISIORid",res.data)
			this.setState({ stendisabled:false,btnsubmit:"Submit",redirect:true,pagename:'/OrderConfirm'})
					
		
			

		});	
}

triggerdelete=(daid) => {
		let r = window.confirm("Do you really want to Delete?");
 if(r == true){
	 var formData = new FormData();
	  formData.append('action','Delete') 
	  formData.append('ad_id',daid)
	 formData.append('userid',localStorage.getItem("ISIuserid"))
		 axios.post(Serverurl+"ajaxaddress.php", formData, {
        }).then(res => {
   
            alert("Deleted Successfully")
     this.Addressapicall(); 
            });	
 }
	
}
triggerdeliveryclick=(adid) => {
	localStorage.setItem("ISIbiladrsid",adid)
	
	this.setState({redirectdate: true})
		 this.setState({ stendisabled:true,btnsubmit:"Processing..."  })  			

}
triggerdeliveryclick=(adid) => {
	localStorage.setItem("ISIshipadrsid",adid)
	
	this.setState({redirectdate: true})
		 this.setState({ stendisabled:true,btnsubmit:"Processing..."  })  			

}
 handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }
renderRedirect = () => {
  if (this.state.redirect) {
      return <Navigate to={this.state.pagename} />
  }
  }	
render(){
	
	if (this.state.redirectdate) {
      return <Navigate to='/Order' />
  }
 if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
	}
return (




					
  <React.Fragment>
  
  <Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse>
  {this.renderRedirect()}

 <FormControl component="fieldset">
  <FormLabel id="demo-controlled-radio-buttons-group">Billing Address &nbsp;&nbsp;&nbsp;
  
  {this.state.data.length>0 && (
  <p className="reodrerbtn" onClick={()=>{this.Changeaddress(2)}}><span className="btnupdate"> <ModeEditOutlineOutlinedIcon className="iconvertical"/>Change Address </span></p>
  )}</FormLabel>
  <RadioGroup value={this.state.radiodefaultValue} aria-label="gender" name="customized-radios">
{this.state.data.map((item,idx) => {
	
 return ( <React.Fragment>	
<FormControlLabel value={item.ad_id} onClick={()=>{this.selectaddress(item.ad_id)}} control={<Radio />}
 label={item.ad_billingname+ " " +item.ad_billingaddress+ ", " +item.ad_billingcity+ ", " +item.ad_billingstate+ ", " +item.ad_billingcountry+ " - " +item.ad_billingpostalcode}  /> 


 </React.Fragment>
)
})}
{this.state.data.length===0 &&(

<FormControlLabel value="0" onClick={this.triggerNewaddres(1)} control={<Radio />} label=" Add Billing Address" />
)}
 </RadioGroup>
    </FormControl>
 

<hr/>


<FormControl component="fieldset">
  <FormLabel id="demo-controlled-radio-buttons-group">Shipping Address  &nbsp;&nbsp;&nbsp;
    {this.state.shipdata.length>0 && (
  <p className="reodrerbtn" onClick={()=>{this.Changeshipaddress(2)}}><span className="btnupdate"> <ModeEditOutlineOutlinedIcon className="iconvertical"/>Change Address </span></p>
  
	)}</FormLabel>
  <RadioGroup value={this.state.shipValue} aria-label="gender" name="customized-radios">
{this.state.shipdata.map((item,idx) => {
	
 return ( <React.Fragment>	
<FormControlLabel value={item.ad_id} onClick={()=>{this.selectshipaddress(item.ad_id)}} control={<Radio />} 
 label={item.ad_billingname+ " " +item.ad_billingaddress+ ", " +item.ad_billingcity+ ", " +item.ad_billingstate+ ", " +item.ad_billingcountry+ " - " +item.ad_billingpostalcode}  /> 


 </React.Fragment>
)
})}

{this.state.shipdata.length===0 &&(
<FormControlLabel value="0" onClick={this.triggerNewaddres(2)} control={<Radio />} label=" Add Shipping Address" />
)}</RadioGroup>
    </FormControl>
<div className="clear"></div>
<br/>
<Button
            type="submit"
             disabled={this.state.stendisabled}
            variant="contained" 
            className="primarybtn"  onClick={this.OnclickOrderSubmit}
            id="submitaddress" inputProps={{tabIndex: "9"  }}
         
          >
           {this.state.btnsubmit} 
          </Button>
</React.Fragment>

)
	
	
	
}
	 
}


