import React, { Component } from "react";
import axios from 'axios';
import Serverurl from './Apiurl';


export default class  MyviewOrder extends Component {
	constructor(props) {
	 super(props);

    this.state = {}
}
addtoCart(id,firstrow){
	


	var formData = new FormData();
	  formData.append('action','Create')
	  
	  formData.append('op_pr_id',id)
	   formData.append('op_sessionid',localStorage.getItem("ISIsession"))
	 formData.append('op_quantity',this.props.qty)
	 
	    formData.append('userid',localStorage.getItem("ISCustid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
			
			
   this.setState({alerttype:'success', errormessage1:'Product Added to Cart',erroropen1:true},()=>{this.props.updateDoneCart() }); 
             window.scrollTo({
				  top: 0
			  })
    
            });		
	
} orderDelete(id){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('pr_id',id)
	  formData.append('sessionid',localStorage.getItem("ISIsession"))
formData.append('userid',localStorage.getItem("ISIuserid"))	
	
	
	 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
    
          this.props.updateDoneCart()
   
    
            });
 }
      } 

render (){

return(
<React.Fragment>
</React.Fragment>
)}
}







