import React, { Component } from "react";

import { Navigate   } from 'react-router-dom';

import { makeStyles } from '@mui/styles'; 


 import Reordericon  from './images/reorder.jpg';


import Serverurl from './Apiurl';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },textField: {
    width: '100%',
  },root: {  
    width: '100%',  
  },container: {  
    maxHeight: 440,  
  }, 
 
}));

export default class  Orderdetails extends Component {
	constructor(props) {
    super();

    this.state = {logredirect:false,pagename:'',Orderdata:[],orderdate: '',orcs_name:'',orcs_id:0 ,Proddata:[],totaldata:0,orderno:'',rowsPaging:[],
	};
	}
	componentDidMount() {
		
		if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
           redirect: true,
			pagename:'/Login'
          })
		  }
		  
		  this.apimyorder();
	}
	 Getvieworderid(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); 
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
	
	apimyorder()
	{
		let search = window.location.href;
let strnoid = this.Getvieworderid('orid',search);
	axios.get(Serverurl+'ajaxorder.php?action=list&or_id='+strnoid)
  .then(resp => {  
		
    if(resp.data.length>0){
		this.OrderProduct(resp.data[0]["or_sessionid"] )
			this.setState({Orderdata:resp.data,orcs_name:resp.data[0]["or_name"],orcs_id:resp.data[0]["or_cs_id"],orderno:resp.data[0]["or_orderno"],orderdate:resp.data[0]["or_createddate"] });	
		
	}
  })
	}
	
	onClickreorder(){
		let search = window.location.href;
let strnoid = this.Getvieworderid('orid',search);
	let r = window.confirm("Do you really want to Re-Order?");
 if(r === true){ //sign Out logic
 localStorage.setItem("ISCustid",this.state.orcs_id)
 localStorage.setItem("ISCustName",this.state.orcs_name)
 var formData = new FormData();
     formData.append('action','reorder')
	 formData.append('or_id',strnoid)
	  formData.append('or_sessionid',localStorage.getItem("ISIsession"))
formData.append('userid',this.state.orcs_id)	
	
	
	 axios.post(Serverurl+"ajaxorder.php", formData, {
        }).then(res => {
    
           
    this.setState({
           redirect: true,
			pagename:'/Mycart'
          })
    
            });
 }
}
	
OrderProduct(sessionid){
	
 axios.get(Serverurl+"ajaxorderproduct.php?action=cartlist&sessionid="+sessionid)

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  Proddata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({Proddata:[],totaldata:0});
	 }
 
    });
}
	





	
	render() {
let totalnetamount=0;



if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	
return (






					
  <React.Fragment>
  <style>{"\
        .cusordername{display:none;}\
      "}</style>
   <table width="100%"  cellpadding="10" cellspacing="0">
    <tr><td >
<h4><b>
  #{this.state.orderno}</b></h4>
</td><td className="order-date">
<h4>
  {this.state.orderdate}</h4>
</td></tr>
     <tr><td colSpan="2" >




</td></tr>
   </table>
   
   
   
<table width="80%" cellpadding="5">
{this.state.Orderdata.map((item,idx) => {
 return ( 	
 <React.Fragment>	
 


 
 <tr><td >
  <p>{item.or_name}<br/>
  {item.or_emailid}
  <br/>
  {item.or_phoneno}<br/>
   <b>How do you like to contact</b>: {item.modeofcaontact}
  </p>
  
  
</td>
<td ><div className="proddetails">
<h2>Order Submitter Details</h2>
</div>
  <p>{item.or_submittedname}<br/>
  {item.or_submittedemailid}
  <br/>
  {item.or_submittedphoneno}
  </p>
  
  
</td>
</tr>
<tr><td><div className="proddetails">
<h2>Billing Address</h2>
</div><p>{item.or_billingaddress},<br/>
	{item.or_billingcity}, {item.or_bilingstate},<br/>
	
	{item.or_country} - {item.or_billingpinode}</p></td>
<td><div className="proddetails">
<h2>Shipping Address</h2>
</div><p>{item.or_shippingaddress},<br/>
	{item.or_shippingcity}, {item.or_shippingstate},<br/>
	
	{item.or_shipcountry} - {item.or_shippingpincode}</p></td>
	
	
	
	</tr> <tr><td colspan="2">
</td>

</tr>
</React.Fragment>
);
})}</table>
   <div className="proddetails myorderheading">
<h2>Product info </h2> 

<p className="primarybtn" onClick={()=>{this.onClickreorder()}}><img src={Reordericon} width="25px" className="iconvertical"/>&nbsp;Reorder</p>
<div className="clear"></div>

</div>
  <table border="1" width="60%"  cellpadding="10" cellspacing="0">
  
  


<tr>
<th>Product</th><th>Quantity</th>
<th>Price</th>

<th>Net Price</th>

</tr>

{this.state.Proddata.map((item,idx) => {
	

 totalnetamount=Number(item.netprice)+Number(totalnetamount)	
	
 return ( 	
 <React.Fragment>	




<tr>
<td>{item.op_pr_title}</td><td align='center'>{item.op_quantity}  


</td>
<td align="right">${item.op_price}</td>

<td align="right">${item.netprice}</td>

</tr>


</React.Fragment>
);
					})}
					<tr><td colSpan={2} align="right"></td><td>Total Price</td><td align="right">${(totalnetamount).toFixed(2)}</td></tr>

</table>




</React.Fragment>

)
	
	
	
}
	 
}


