import React ,{Component} from 'react';
import { makeStyles } from '@mui/styles';  
import { Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import PropTypes from 'prop-types';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ordericon from './images/create-order-icon.jpg';
import save from './images/Save.jpg';
import delet from './images/delete.jpg';
import view from './images/view.jpg';
import TextField from '@mui/material/TextField';
import cart from './images/top-bar-icon-4.jpg';
import Table from '@mui/material/Table';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Smartbasket extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',SmartbasketData:[],qty:props.min,min:1      }
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentDidMount() {
	
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'/Login'
          })
		  }
	
	document.title = "Baawarchi - Smart Basket";
	
	
 
  this.apicall(); 

}



apicall(){
	
	 axios.get(Serverurl+"ajaxorderproduct.php?action=list&userid="+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  SmartbasketData: resp.data,  
			  qty: resp.data[0].ordqty,
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }

    });
	
	
}
  
addtoCarts(id,firstrow){
	

	const updatedArray = [...this.state.SmartbasketData];
	let qty=updatedArray[firstrow].ordqty;

	var formData = new FormData();
	  formData.append('action','Create')
	  
	  formData.append('op_pr_id',id)
	   formData.append('op_sessionid',localStorage.getItem("ISIsession"))
	 formData.append('op_quantity',qty)
	 
	    formData.append('userid',localStorage.getItem("ISCustid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
			
		 let strqty=parseFloat(localStorage.getItem("IScartqty"))+parseFloat(1);
			  localStorage.setItem("IScartqty",strqty)
			  this.props.parentbasCallback(
            strqty
        );	
   this.setState({ logredirect: true,
			pagename:'/Mycart'}); 
         
    
            });		
	
}
    
   Productqtychange= (firstrow) => event  => { 
	 let val = event.target.value;
const updatedArray = [...this.state.SmartbasketData];


		updatedArray[firstrow].ordqty = val;
			  

 this.setState({SmartbasketData: updatedArray,});
}
	

render() {
	
if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }
  
  	 
    
  return (
  

   
 
  
    <React.Fragment>
     
 
 {this.state.SmartbasketData.length===0 && (
  <h3 className="aligncentr"><a href="#/product"> We noticed you haven't placed any orders with us. Please click here to proceed with ordering</a></h3>
  )}
<div className="smartbasket">



 
 
<Table stickyHeader aria-label="sticky table" id="smartbasket"> 
		        

                     {this.state.SmartbasketData.map((item,index) => {  
           return ( <React.Fragment>
<tr >   
               
			
            
	
           
	<td >{item.prdimg!=="" && (	<img src={Serverurl+"uploads/"+item.prdimg+""} width="100px" height="100px"/>	
	
	)}
	{item.prdimg==="" && (
<img src={Serverurl+"uploads/noimg.jpg"} width="100px" height="100px"/>
	)}
	</td>
	           
	<td>	<h3>{item.pr_title}</h3><p>Last Ordered: <b>{item.ordate}</b></p>	
	</td>
	           
	<td	
	
	className="prodcart"><TextField
                               ref="op_quantity"
                              name="op_quantity"
							  className="counters"
                              value={item.ordqty}              
                                    
                            onChange={this.Productqtychange(index)}/>
							
							<p className="addtocart" onClick={()=>{this.addtoCarts(item.pr_id,index)}}><span className="smartadd">Add to cart</span><span className="smartimg"><img src={cart}/></span></p>	
	
	</td>
	           
	
<td><b>$ {item.price}</b></td>
			   
		   </tr> 
			  












				</React.Fragment>	
				);
					})}
                     

                   
 </Table>


   </div>
 
 </React.Fragment>
		
	  
	 


  );
  }	
   
   


}